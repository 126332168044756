var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "waitBox" },
      [
        _c("el-row", { attrs: { gutter: 20 } }, [
          _c(
            "div",
            { staticClass: "card d2-pt" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("订单处理")]),
                  _c(
                    "div",
                    { staticClass: "ul" },
                    [
                      _c("el-col", [
                        _vm.isShow("store-order-list")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("orderSearch", {
                                      activeName: "stayHouse"
                                    })
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("待出库")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.paidCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("el-col", { staticStyle: { "padding-right": "0" } }, [
                        _vm.isShow("store-order-list")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("orderSearch")
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("已超时未出库")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.overdueCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("商品")]),
                  _c(
                    "div",
                    { staticClass: "ul" },
                    [
                      _c("el-col", { staticStyle: { "padding-left": "0" } }, [
                        _vm.isShow("store-offshelf-manage")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("comsTrade", {
                                      goodsStatus: 4
                                    })
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("系统下架商品")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.offShelfCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("其他")]),
                  _c(
                    "div",
                    { staticClass: "ul" },
                    [
                      _c("el-col", { staticStyle: { "padding-left": "0" } }, [
                        _vm.isShow("store-transfers-manage")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("wallet")
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("提现 - 待上传发票")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.invoiceCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _c("el-col", { staticClass: "d2-mt" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("售后处理")]),
                  _c(
                    "div",
                    { staticClass: "ul" },
                    [
                      _c("el-col", [
                        _vm.isShow("store-cancel-manage")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("cancelOrder")
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("待审核取消订单")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.cancelAuditCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("el-col", [
                        _vm.isShow("store-refund-manage")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("afterSale", {
                                      activeName: "stayExamine"
                                    })
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("待审核服务单")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.refundAuditCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("el-col", [
                        _vm.isShow("store-refund-manage")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("afterSale", {
                                      activeName: "stayReceiv"
                                    })
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("待收货服务单")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.deliveryCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("el-col", [
                        _vm.isShow("store-refund-manage")
                          ? _c(
                              "div",
                              {
                                staticClass: "li",
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("afterSale", {
                                      activeName: "stayExchange"
                                    })
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _c("div", { staticClass: "msg" }, [
                                  _vm._v("待换新货服务单")
                                ]),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v(_vm._s(_vm.tabData.exchangeCount))
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBox" }, [
      _c("div", { staticClass: "block" }),
      _c("div", { staticClass: "text" }, [_vm._v("待办事项")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }