var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d2-pb" }, [
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _vm._m(0),
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              border: "",
              "header-cell-style": { background: "#f9fafc" }
            }
          },
          [
            _c(
              "el-table-column",
              { attrs: { label: "买家", align: "center" } },
              [_vm._v("增值税专用发票")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "收货人", align: "center" } },
              [_vm._v("XXXXXX有限公司")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "收货地址", align: "center" } },
              [_vm._v("91330281784324790N")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "手机号码", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "买家留言", align: "center" } },
              [_vm._v("XXXXXXX")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _vm._m(1),
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              border: "",
              "header-cell-style": { background: "#f9fafc" }
            }
          },
          [
            _c(
              "el-table-column",
              { attrs: { label: "发票类型", align: "center" } },
              [_vm._v("增值税专用发票")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "发票抬头", align: "center" } },
              [_vm._v("XXXXXX有限公司")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "纳税人识别号", align: "center" } },
              [_vm._v("91330281784324790N")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "地址", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "电话", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "开户银行", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "银行账号", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "收票人姓名", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "收票人手机", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "收票人地址", align: "center" } },
              [_vm._v("XXXXXXX")]
            ),
            _c(
              "el-table-column",
              { attrs: { label: "发票内容", align: "center" } },
              [_vm._v("商品明细")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d2-mt" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              border: "",
              "header-cell-style": { background: "#f9fafc" }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "date",
                label: "商品编号",
                width: "180",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "商品名称",
                width: "180",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "address", label: "商品价格", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "address", label: "属性", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "address", label: "商品数量", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "address", label: "库存", align: "center" }
            }),
            _c("el-table-column", {
              attrs: { prop: "address", label: "小计", align: "center" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("i", {
        staticClass: "el-icon-user-solid",
        staticStyle: { "margin-right": "8px" }
      }),
      _vm._v("收货人信息")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("i", {
        staticClass: "el-icon-s-order",
        staticStyle: { "margin-right": "8px" }
      }),
      _vm._v("发票信息")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }