var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      attrs: { betterScroll: "" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "el-card",
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c("el-descriptions-item", { attrs: { label: "退保编号" } }, [
                _vm._v(_vm._s(_vm.dataObj.depositNo))
              ]),
              _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                _vm._v(_vm._s(_vm.dataObj.depositTime))
              ]),
              _c("el-descriptions-item", { attrs: { label: "审核结果" } }, [
                _vm._v("审核不通过")
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "原因", span: 3 } },
                [_vm._v(" " + _vm._s(_vm.dataObj.rejectReason) + " ")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "d2-m" }),
          _c(
            "el-descriptions",
            {
              attrs: {
                title: "退保金额：￥" + _vm.dataObj.depositAmount,
                column: 1
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "开户名称" } }, [
                _vm._v(_vm._s(_vm.dataObj.payeeAccountName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "开户账号" } }, [
                _vm._v(_vm._s(_vm.dataObj.payeeAccount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "开户银行支行" } }, [
                _vm._v(_vm._s(_vm.dataObj.payeeBankName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "联行号" } }, [
                _vm._v(" " + _vm._s(_vm.dataObj.payeeBankNo) + " ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }