var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "d2-p-10 d2-pt-20",
      attrs: { shadow: "always", "body-style": { padding: 0, maring: 0 } }
    },
    [
      _c(
        "el-table",
        {
          staticClass: "d2-m ",
          attrs: {
            data: _vm.form.records,
            border: "",
            "header-cell-style": { background: "#F6F6F6" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "brandName",
              label: "品牌名称",
              width: "180",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brandActivate",
              label: "授权状态",
              width: "180",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.brandActivate ? "正常" : "停用"))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "address", label: "授权类目", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(_vm._s(_vm.brandCategories(row.brandCategories)))
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("d2-pagination", {
        attrs: { total: _vm.form.total },
        on: { pagination: _vm.pagination }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }