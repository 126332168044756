import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var initForm = {
  orderNo: '',
  orderTimeRange: {
    start: '',
    end: ''
  },
  orderStatus: 2
};
import pintComp from '@/components/pint-comp/printComp.vue';
import { cloneDeep } from 'lodash';
export default {
  components: {
    pintComp: pintComp
  },
  meta: {
    title: '出库单打印',
    auth: true,
    authorityValue: 'store-delivery-records'
  },
  name: 'outOrder',
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'deliveryList',
        params: cloneDeep(initForm)
      },
      multipleSelection: []
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    printS: function printS() {
      var l = this.multipleSelection.map(function (v) {
        return v.orderId;
      });

      if (l.length > 0) {
        this.printComp(this.multipleSelection);
      } else {
        YL.message('请选择需要打印的出库单', 'error');
      }
    },
    handleSelectionChange: function handleSelectionChange(e) {
      this.multipleSelection = e;
    }
  }
};