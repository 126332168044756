import "core-js/modules/es.array.filter";
import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      btnList: [{
        label: '智能客服',
        key: 'chat',
        icon: require("@/assets/image/h-".concat(1, ".png")),
        value: 'store-router-page'
      }, {
        label: '商品发布',
        key: 'addTrade',
        icon: require("@/assets/image/h-".concat(2, ".png")),
        value: 'store-onshelf-manage'
      }, {
        label: '订单查询与跟踪',
        key: 'orderSearch',
        icon: require("@/assets/image/h-".concat(3, ".png")),
        value: 'store-order-list'
      }, {
        label: '退换货服务单',
        key: 'afterSale',
        icon: require("@/assets/image/h-".concat(4, ".png")),
        value: 'store-refund-manage'
      }, {
        label: '在售商品管理',
        key: 'saleTrade',
        icon: require("@/assets/image/h-".concat(5, ".png")),
        value: 'store-onshelf-manage'
      }, {
        label: '钱包提现',
        key: 'wallet',
        icon: require("@/assets/image/h-".concat(6, ".png")),
        value: 'store-transfers-manage'
      }]
    };
  },
  created: function created() {
    var _this = this;

    // this.btnList = [
    //   { label: '智能客服', key: 'chat',value:'' },
    //   { label: '商品发布', key: 'addTrade',value:'store-onshelf-manage' },
    //   { label: '订单查询与跟踪', key: 'orderSearch',value:'store-order-list' },
    //   { label: '退换货服务单', key: 'afterSale',value:'store-refund-manage' },
    //   { label: '在售商品管理', key: 'saleTrade',value:'store-onshelf-manage' },
    //   { label: '钱包提现', key: 'wallet',value:'store-transfers-manage' }
    // ].map((v, i) => ({
    //   label: v.label,
    //   icon: require(`@/assets/image/h-${i + 1}.png`),
    //   key: v.key
    // }))
    this.btnList = this.btnList.filter(function (x) {
      return _this.isShow(x.value) || x.value == 'store-router-page';
    });
  },
  computed: _objectSpread({}, mapState('d2admin', {
    menu: function menu(state) {
      return state.permission.routeKey;
    }
  })),
  methods: {
    isShow: function isShow(auto) {
      return this.isBtnGroud(this.menu, auto);
    },
    goRouteInit: function goRouteInit(key) {
      if (key === 'chat') {
        this.goRoute(key, {
          userId: 'custom_service'
        });
      } else {
        this.goRoute(key);
      }
    }
  }
};