import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";

/**
 * 一些业务的数据字典
 */
//  订单来源
var orderPlatform = [{
  value: 'app',
  label: 'App'
}, {
  value: 'h5',
  label: 'H5'
}, {
  value: 'mini',
  label: '微信小程序'
}]; //订单状态

var overdueReason = [{
  value: 1,
  label: '商家原因与消费者达成一致'
}, {
  value: 2,
  label: '消费者原因与商家达成一致'
}, {
  value: 3,
  label: '系统原因'
}, {
  value: 4,
  label: '紧急异常时间'
}, {
  value: 5,
  label: '国际大型会议/政策'
}, {
  value: 6,
  label: '天气原因'
}, {
  value: 7,
  label: '大促期间、节假日'
}]; //订单状态

var orderStatus = [{
  value: 0,
  label: '全部'
}, {
  value: 1,
  label: '待付款'
}, {
  value: 2,
  label: '待出库'
}, {
  value: 3,
  label: '已出库'
}, {
  value: 4,
  label: '已签收'
}, {
  value: 5,
  label: '已完成'
}, {
  value: 6,
  label: '已取消'
}, {
  value: 7,
  label: '已取消'
}, {
  value: 8,
  label: '已取消'
}];
var exchangeGoods = [{
  label: '不支持',
  value: 1
}, {
  label: '支持七天无理由换货',
  value: 2
}];
var refundType = [{
  label: '退货',
  value: 1
}, {
  label: '换货',
  value: 2
}];
var refundWithoutReason = [{
  label: '不支持七天无理由退货',
  value: 1
}, {
  label: '支持七天无理由退货',
  value: 2
}, {
  label: '支持七天无理由退货(拆封后不支持)',
  value: 3
}, {
  label: '支持七天无理由退货(使用后不支持)',
  value: 4
}];
var orderGoodsStatus = [{
  label: '全部待售',
  value: 0
}, {
  label: '在售',
  value: 1
}, {
  label: '尚未发售',
  value: 2
}, {
  label: '自主下架',
  value: 3
}, {
  label: '系统下架',
  value: 4
}, {
  label: '滞销下架',
  value: 5
}];
var orderGoodsStatus2 = [{
  label: '全部待售',
  value: 0
}, {
  label: '尚未发售',
  value: 2
}, {
  label: '自主下架',
  value: 3
}, {
  label: '系统下架',
  value: 4
}, {
  label: '滞销下架',
  value: 5
}];
var refundStatus = [{
  label: '待审核',
  value: 1
}, // { label: '已同意', value: 2 },
{
  label: '待收货',
  value: 2
}, {
  label: '已拒绝',
  value: 3
}, {
  label: '待收货',
  value: 4
}, // { label: '商品寄回', value: 4 },
{
  label: '已完成',
  value: 5
}, // { label: '商品收货', value: 5 },
{
  label: '商家寄出新货',
  value: 6
}, {
  label: '卖家收到新货',
  value: 7
}, {
  label: '已完成',
  value: 8
}, // { label: '商家收到寄回商品，拒绝退换', value: 9 },
{
  label: '拒绝',
  value: 9
}, {
  label: '用户撤销退换',
  value: 10
}, {
  label: '超时未寄回货物，自动关闭',
  value: 11
}, {
  label: '待审核即将超时',
  value: 101
}, {
  label: '待收货即将超时',
  value: 102
}, {
  label: '待换新货即将超时',
  value: 103
}];
var exchangeStatus = [{
  label: '待审核',
  value: 1
}, // { label: '已同意', value: 2 },
{
  label: '待收货',
  value: 2
}, {
  label: '已拒绝',
  value: 3
}, {
  label: '待收货',
  value: 4
}, // { label: '商品寄回', value: 4 },
{
  label: '待换新货',
  value: 5
}, // { label: '商品收货', value: 5 },
// { label: '商家寄出新货', value: 6 },
{
  label: '待客户收货',
  value: 6
}, {
  label: '卖家收到新货',
  value: 7
}, {
  label: '已完成',
  value: 8
}, // { label: '商家收到寄回商品，拒绝退换', value: 9 },
{
  label: '拒绝',
  value: 9
}, {
  label: '用户撤销退换',
  value: 10
}, {
  label: '超时未寄回货物，自动关闭',
  value: 11
}, {
  label: '待审核即将超时',
  value: 101
}, {
  label: '待收货即将超时',
  value: 102
}, {
  label: '待换新货即将超时',
  value: 103
}];
var cancelStatus = [{
  label: '待审核',
  value: 1
}, {
  label: '审核通过',
  value: 2
}, {
  label: '驳回',
  value: 3
}, {
  label: '退款完成',
  value: 8
}, {
  label: '用户撤销',
  value: 4
}, {
  label: '用户撤销',
  value: 10
}];
var houseStatus = [{
  label: '已出库',
  value: 1
}, {
  label: '未出库',
  value: 2
}];
var invoiceTitleType = [{
  value: '',
  label: '未开发票'
}, {
  value: 1,
  label: '普通发票'
}, {
  value: 2,
  label: '增值税专用发票'
}, {
  value: 3,
  label: '不开发票'
}];
var adminTab = [{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '好评'
}, {
  value: 2,
  label: '中评'
}, {
  value: 2,
  label: '差评'
}];
var whether = [{
  value: '',
  label: '全部'
}, {
  value: true,
  label: '是'
}, {
  value: false,
  label: '否'
}];
var walletStatus = [{
  value: 1,
  label: '等待平台审核'
}, {
  value: 2,
  label: '等待平台开票'
}, {
  value: 3,
  label: '审核未通过'
}, {
  value: 4,
  label: '等待平台打款'
}, {
  value: 5,
  label: '平台已打款'
}];
var dict = {
  walletStatus: walletStatus,
  adminTab: adminTab,
  whether: whether,
  orderPlatform: orderPlatform,
  overdueReason: overdueReason,
  orderStatus: orderStatus,
  exchangeGoods: exchangeGoods,
  refundWithoutReason: refundWithoutReason,
  orderGoodsStatus: orderGoodsStatus,
  orderGoodsStatus2: orderGoodsStatus2,
  refundType: refundType,
  refundStatus: refundStatus,
  cancelStatus: cancelStatus,
  houseStatus: houseStatus,
  invoiceTitleType: invoiceTitleType,
  exchangeStatus: exchangeStatus
};
export default {
  namespaced: true,
  state: _objectSpread({}, dict)
};