import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import d2Container from './d2-container';
import veLine from 'v-charts/lib/line.common';
import goodsCard from './goods-card/index.vue'; // 注意 有些组件使用异步加载会有影响

Vue.component('ve-line', veLine);
Vue.component('d2-container', d2Container);
Vue.component('d2-layer', function () {
  return import('./d2-layer');
});
Vue.component('d2-search', function () {
  return import('./d2-search');
});
Vue.component('d2-upload', function () {
  return import('./d2-upload');
});
Vue.component('d2-icon', function () {
  return import('./d2-icon');
});
Vue.component('d2-quill', function () {
  return import('./d2-quill');
});
Vue.component('d2-page-head', function () {
  return import('./d2-page-head');
});
Vue.component('d2-icon-svg', function () {
  return import('./d2-icon-svg/index.vue');
});
Vue.component('d2-code', function () {
  return import('./d2-code');
});
Vue.component('d2-pagination', function () {
  return import('./d2-pagination');
});
Vue.component('d2-city', function () {
  return import('./d2-city');
});
Vue.component('goods-card', goodsCard);