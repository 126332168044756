var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "csBox btn-text can-hover" },
    [
      _c("el-image", {
        staticStyle: { width: "26px", height: "24px" },
        attrs: { src: _vm.img }
      }),
      _c("div", { staticClass: "inBox", on: { click: _vm.goRoute } }, [
        _c("div", [_vm._v("联系要炼客服")]),
        _c("div", [_vm._v("8:00-22:00")])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }