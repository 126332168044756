var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c("el-card", { attrs: { shadow: "never" } }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ]),
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.form, "label-width": "120px" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "分类名称" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.typeName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "typeName", $$v)
                        },
                        expression: "form.typeName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "上级分类" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", disabled: "" },
                        model: {
                          value: _vm.form.preTypeId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "preTypeId", $$v)
                          },
                          expression: "form.preTypeId"
                        }
                      },
                      _vm._l(_vm.options, function(item) {
                        return _c("el-option", {
                          key: item.goodsTypeId,
                          attrs: {
                            label: item.typeName,
                            value: item.goodsTypeId
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "排序" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.form.typeSort,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "typeSort", $$v)
                        },
                        expression: "form.typeSort"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "是否显示" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.typeShow,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "typeShow", $$v)
                          },
                          expression: "form.typeShow"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: true } }, [
                          _vm._v("是")
                        ]),
                        _c("el-radio", { attrs: { label: false } }, [
                          _vm._v("否")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "分类图标" } },
                  [
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            "on-success": function(res) {
                              return _vm.handleSuccess(res, "typeIcon")
                            },
                            "before-upload": _vm.beforeUpload
                          }
                        },
                        "el-upload",
                        _vm.uploadData,
                        false
                      ),
                      [
                        _vm.form.typeIcon
                          ? _c("el-image", {
                              staticStyle: { width: "100px" },
                              attrs: { src: _vm.form.typeIcon, fit: "fill" }
                            })
                          : _c(
                              "el-button",
                              { attrs: { icon: "el-icon-upload" } },
                              [_vm._v("选择上传文件")]
                            )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }