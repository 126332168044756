//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
var initForm = {};
export default {
  meta: {
    title: '保证金',
    auth: true,
    authorityValue: "store-store-bond-info"
  },
  name: 'bond',
  data: function data() {
    var _this = this;

    return {
      dataForm: {},
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'depositList',
        params: cloneDeep(initForm),
        parser: function parser(res) {
          _this.dataForm = res;
          return {
            records: res.deposits
          };
        }
      }
    };
  },
  methods: {
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    }
  }
};