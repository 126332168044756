var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      attrs: { betterScroll: "" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.goBack } },
                [_vm._v("返回")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "el-card",
        [
          _c(
            "el-descriptions",
            { attrs: { column: 3 } },
            [
              _c("el-descriptions-item", { attrs: { label: "缴纳编号" } }, [
                _vm._v(_vm._s(_vm.dataObj.depositNo))
              ]),
              _c("el-descriptions-item", { attrs: { label: "提交时间" } }, [
                _vm._v(_vm._s(_vm.dataObj.depositTime))
              ]),
              _c("el-descriptions-item", { attrs: { label: "审核结果" } }, [
                _vm._v("审核不通过")
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "原因", span: 3 } },
                [
                  _c(
                    "el-link",
                    { attrs: { type: "danger", underline: false } },
                    [_vm._v(_vm._s(_vm.dataObj.rejectReason))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c("el-descriptions-item", { attrs: { label: "公司名称" } }, [
                _vm._v(_vm._s(_vm.dataObj.payeeAccountName))
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c(
                    "el-link",
                    { attrs: { type: "primary", underline: false } },
                    [_vm._v(_vm._s(_vm.dataObj.payerAccountName))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "d2-m",
              attrs: {
                data: [_vm.dataObj],
                border: "",
                "header-cell-style": { background: "#F6F6F6" }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "depositAmount",
                  label: "转账金额(元)",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payerAccountName",
                  label: "开户名称",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payerAccount",
                  label: "开户账号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payerBankName",
                  label: "开户银行支行",
                  align: "center"
                }
              })
            ],
            1
          ),
          _c(
            "el-descriptions",
            { attrs: { column: 1 } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "转账凭证" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: _vm.dataObj.depositImage }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }