var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "distribution",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            inline: false,
            "label-width": "200px"
          }
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "10px" } } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "1000px" },
                  attrs: { label: "所在地区：", prop: "province" }
                },
                [_c("d2-city", { on: { change: _vm.cityChange } })],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址：", prop: "details" } },
                [
                  _c(
                    "div",
                    { attrs: { flex: "main" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.details,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "details", $$v)
                          },
                          expression: "formData.details"
                        }
                      }),
                      _c("span", [
                        _vm._v("注：英文数字不能超过100个,汉字不能超过50个")
                      ])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮编：", prop: "postCode" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 6 },
                    model: {
                      value: _vm.formData.postCode,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "postCode", $$v)
                      },
                      expression: "formData.postCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话：", prop: "phoneNumber" } },
                [
                  _c(
                    "div",
                    { attrs: { flex: "main" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formData.phoneNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "phoneNumber", $$v)
                          },
                          expression: "formData.phoneNumber"
                        }
                      }),
                      _c("span", [_vm._v("注：支持手机号和固定电话")])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人：", prop: "contactName" } },
                [
                  _c(
                    "div",
                    { attrs: { flex: "main" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "5" },
                        model: {
                          value: _vm.formData.contactName,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "contactName", $$v)
                          },
                          expression: "formData.contactName"
                        }
                      }),
                      _c("span", [_vm._v("注：不能超过5个字符")])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否默认：", prop: "defaultAddress" } },
                [
                  _c("el-checkbox", {
                    attrs: { indeterminate: false },
                    model: {
                      value: _vm.formData.defaultAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "defaultAddress", $$v)
                      },
                      expression: "formData.defaultAddress"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "d2-mt" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }