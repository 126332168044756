import { render, staticRenderFns } from "./PageContent.vue?vue&type=template&id=7a459d05&scoped=true&"
import script from "./PageContent.vue?vue&type=script&lang=js&"
export * from "./PageContent.vue?vue&type=script&lang=js&"
import style0 from "./PageContent.vue?vue&type=style&index=0&id=7a459d05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a459d05",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\mzkj\\要炼\\yl-pc-business\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a459d05')) {
      api.createRecord('7a459d05', component.options)
    } else {
      api.reload('7a459d05', component.options)
    }
    module.hot.accept("./PageContent.vue?vue&type=template&id=7a459d05&scoped=true&", function () {
      api.rerender('7a459d05', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/global/base-layout/PageContent.vue"
export default component.exports