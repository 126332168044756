import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    form: {
      type: Object,
      default: {
        total: 0,
        records: []
      }
    }
  },
  data: function data() {
    return {
      tableData: [],
      total: 0
    };
  },
  methods: {
    pagination: function pagination(e) {
      this.$emit('dataBindBrand', e);
    },
    brandCategories: function brandCategories(val) {
      var str = [];
      val.map(function (e) {
        var item = e.items.map(function (_e) {
          return _e.goodsTypeName;
        }).join('、'); // return str = e.goodsTypeName + '/' + e.goodsTypeNameSecond + '/' + item

        if (e.goodsTypeName) str.push(e.goodsTypeName);
        if (e.goodsTypeNameSecond) str.push(e.goodsTypeNameSecond);
        if (item) str.push(item);
        return str;
      });
      return str.join('/');
    }
  },
  filters: {
    types: function types(val) {
      if (!val) return '全部';
      var names = val.map(function (v) {
        var n = v.goodsTypeName;
        return n;
      });
      return names.join('/');
    }
  }
};