var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-body" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "page-card d2-m d2-p" },
      [
        _c(
          "el-result",
          { staticStyle: { padding: "100px 0" } },
          [
            _c("template", { slot: "title" }, [
              _c("span", [_vm._v(_vm._s(_vm.subTitle))])
            ]),
            _c(
              "template",
              { slot: "icon" },
              [
                _c("el-image", {
                  staticClass: "d2-mb",
                  staticStyle: { width: "100px" },
                  attrs: { src: _vm.imgUrl }
                })
              ],
              1
            ),
            _c(
              "template",
              { slot: "extra" },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/wallet")
                      }
                    }
                  },
                  [_vm._v("查看提现记录")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "page-header-title" }, [_vm._v("提交提现申请")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }