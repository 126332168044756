export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * @description 商品分类
     */
    goodsTypes: function goodsTypes() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/goodsTypes',
        data: data
      });
    },

    /**
     * @description 添加分类
     */
    saveStoreGoodsType: function saveStoreGoodsType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/saveStoreGoodsType',
        data: data
      });
    },

    /**
     * @description 店内商品分类列表
     */
    storeGoodsTypes: function storeGoodsTypes() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/storeGoodsTypes',
        data: data
      });
    },

    /**
     * @description 删除店内商品分类
     */
    deleteStoreGoodsType: function deleteStoreGoodsType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/deleteStoreGoodsType',
        data: data
      });
    },

    /**
     * @description 商品分类详情
     */
    storeGoodsTypeDetails: function storeGoodsTypeDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/storeGoodsTypeDetails',
        data: data
      });
    },

    /**
     * @description 评价列表
     */
    goodsEvaluateList: function goodsEvaluateList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/goodsEvaluateList',
        data: data
      });
    },

    /**
     * @description 评价详情
     */
    goodsEvaluateDetails: function goodsEvaluateDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/goodsEvaluateDetails',
        data: data
      });
    },

    /**
     * @description 回复评价
     */
    replyGoodsEvaluate: function replyGoodsEvaluate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/replyGoodsEvaluate',
        data: data
      });
    },

    /**
     * @description 商品列表
     */
    goodsList: function goodsList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/goodsList',
        data: data
      });
    },

    /**
     * @description 商品详情
     */
    goodsDetails: function goodsDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/goodsDetails',
        data: data
      });
    },

    /**
     * @description 商品库存
     */
    getGoodsStocks: function getGoodsStocks() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/getGoodsStocks',
        data: data
      });
    },

    /**
     * @description 商品上架/下架
     */
    onShelf: function onShelf() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/onShelf',
        data: data
      });
    },

    /**
     * @description 商品删除
     */
    deleteGoods: function deleteGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/deleteGoods',
        data: data
      });
    },

    /**
     * @description 保存商品
     */
    saveGoods: function saveGoods() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/saveGoods',
        data: data
      });
    },

    /**
     * @description 迁移类目
     */
    updateType: function updateType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/updateType',
        data: data
      });
    },

    /**
     * @description 销售属性列表
     */
    sellAttributes: function sellAttributes() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/sellAttributes',
        data: data
      });
    },

    /**
     * @description 销售属性详情
     */
    sellAttributeDetails: function sellAttributeDetails() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/sellAttributeDetails',
        data: data
      });
    },

    /**
     * @description 保存属性值
     */
    saveSellAttributeItem: function saveSellAttributeItem() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/saveSellAttributeItem',
        data: data
      });
    },

    /**
     * @description 回复模板列表
     */
    replyTemplates: function replyTemplates() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/store/replyTemplates',
        data: data
      });
    },

    /**
     * @description 保存回复模板
     */
    saveReplyTemplate: function saveReplyTemplate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/store/saveReplyTemplate',
        data: data
      });
    },

    /**
     * @description 删除回复模板
     */
    deleteReplyTemplate: function deleteReplyTemplate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/store/deleteReplyTemplate',
        data: data
      });
    },

    /**
     * @description 商品违规原因
     */
    violationReason: function violationReason() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/violationReason',
        data: data
      });
    },

    /**
     * @description 商品违规原因
     */
    updateStoreType: function updateStoreType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/goods/updateStoreType',
        data: data
      });
    }
  };
});