var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-body" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "page-card d2-m d2-p" },
      [
        _c(
          "el-result",
          { staticStyle: { padding: "100px 0" } },
          [
            _c(
              "template",
              { slot: "title" },
              [
                _c("span", [_vm._v(_vm._s(_vm.subTitle))]),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "20px" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "100px" },
                        attrs: { round: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.replace("/login")
                          }
                        }
                      },
                      [_vm._v("返回登录页")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "icon" },
              [
                _c("el-image", {
                  staticClass: "d2-mb",
                  staticStyle: { width: "100px" },
                  attrs: { src: _vm.imgUrl }
                })
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "page-header-title" }, [_vm._v("入驻审核中")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }