import "core-js/modules/es.function.name";
import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import comp from './comp/index';
export default {
  meta: {
    title: '退换货服务单',
    auth: true,
    authorityValue: "store-refund-manage"
  },
  name: 'afterSale',
  components: _objectSpread({}, comp),
  data: function data() {
    return {
      activeName: 'stayExamine',
      tab: [{
        label: '全部',
        type: 'afterAll',
        refundStatus: ''
      }, {
        label: '待审核',
        type: 'stayExamine',
        limt: 0,
        refundStatus: 1
      }, {
        label: '待收货',
        type: 'stayReceiv',
        limt: 0,
        refundStatus: 2
      }, {
        label: '待换新货',
        type: 'stayExchange',
        limt: 0,
        refundStatus: 5
      }],
      banner: [{
        type: 'stayExamine',
        label: '待审核即将超时',
        tip: '距离审核超时不足24h，预警当前服务单。',
        limt: 10
      }, {
        type: 'stayReceiv',
        label: '待收货即将超时',
        tip: '退换服务单，从填写运单号距离收货超时不足5天，预警当前服务单。',
        limt: 10
      }, {
        type: 'stayExchange',
        label: '待换新货即将超时',
        tip: '退货服务单，从收货距离处理超时不足1.5天，预警当前服务单。',
        limt: 10
      }]
    };
  },
  methods: {
    tabClick: function tabClick(l) {
      this.activeName = l.type;
    },
    handleClick: function handleClick(l) {
      this.activeName = l.name;
    }
  },
  created: function created() {
    var query = this.$route.query;

    if (query.activeName) {
      this.activeName = query.activeName;
    }
  }
};