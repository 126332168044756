import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
var route = [//注册
{
  path: '/account',
  name: 'account',
  meta: {
    auth: false,
    title: '注册账号'
  },
  component: _import('register/account')
}, //忘记密码
{
  path: '/forgetPwd',
  name: 'forgetPwd',
  meta: {
    auth: false,
    title: '忘记密码'
  },
  component: _import('user/forgetPwd')
}, //入驻申请
{
  path: '/settled',
  name: 'settled',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '入驻申请'
  }),
  component: _import('register/settled')
}, //入驻审核
{
  path: '/resident',
  name: 'resident',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '入驻审核'
  }),
  component: _import('register/resident')
}, //入驻审核
{
  path: '/resError',
  name: 'resError',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '入驻审核未通过'
  }),
  component: _import('register/resident/resError')
}, //入驻审核通过
{
  path: '/resSucc',
  name: 'resSucc',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '入驻审核通过'
  }),
  component: _import('register/resident/resSucc')
}, //入驻协议
{
  path: '/settleAgreement',
  name: 'settleAgreement',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '入驻协议'
  }),
  component: _import('register/settleAgreement')
}, //入驻协议
{
  path: '/regAgreement',
  name: 'regAgreement',
  meta: {
    auth: false,
    title: '要炼企业用户注册协议'
  },
  component: _import('register/regAgreement')
}];
export default route;