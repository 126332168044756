var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "d2-p-10 d2-pt-20",
      attrs: { shadow: "always", "body-style": { padding: 0, maring: 0 } }
    },
    [
      _c(
        "el-table",
        {
          staticClass: "d2-m w500",
          attrs: {
            data: _vm.form.businessCategories,
            border: "",
            "header-cell-style": { background: "#F6F6F6" }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "goodsTypeName",
              label: "一级类目",
              width: "180",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsTypeNameSecond",
              label: "二级类目",
              width: "180",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "address", label: "三级类目", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.items
                            .map(function(v) {
                              return v.goodsTypeName
                            })
                            .join(",")
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }