var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm._l(_vm.tabList, function(l, i) {
            return [
              _c(
                "el-tab-pane",
                { key: i, attrs: { label: l.label, name: l.name } },
                [
                  l.name == _vm.activeName && _vm.load
                    ? _c(_vm.activeName, {
                        tag: "component",
                        attrs: { uploadToken: _vm.uploadToken, form: l.form },
                        on: {
                          dataBindBrand: _vm.dataBindBrand,
                          dataBind: _vm.dataBindBasics
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }