var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-body" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "page-card d2-m d2-p" },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              attrs: {
                "label-position": "right",
                rules: _vm.rules,
                model: _vm.formData,
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "storeType", label: "入驻类型：" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formData.storeType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "storeType", $$v)
                        },
                        expression: "formData.storeType"
                      }
                    },
                    [_vm._v("第三方店铺入驻")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.formData.storeType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "storeType", $$v)
                        },
                        expression: "formData.storeType"
                      }
                    },
                    [_vm._v("自营合作")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "form-tit" }, [_vm._v("营业执照")]),
              _c(
                "div",
                { staticClass: "page-card-box" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d2-pt",
                      attrs: {
                        prop: "businessLicenseImage",
                        label: "营业执照照片："
                      }
                    },
                    [
                      _c(
                        "el-upload",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              "on-success": function(res) {
                                return _vm.handleSuccess(
                                  res,
                                  "businessLicenseImage"
                                )
                              },
                              "before-upload": _vm.beforeUpload
                            }
                          },
                          "el-upload",
                          _vm.uploadData,
                          false
                        ),
                        [
                          _vm.formData.businessLicenseImage
                            ? _c("el-image", {
                                staticClass: "avatar",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.formData.businessLicenseImage
                                }
                              })
                            : _c("div", [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon"
                                })
                              ])
                        ],
                        1
                      ),
                      _c("span", { staticClass: "form-tip" }, [
                        _vm._v(
                          "请上传清晰营业执照照片，图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持png，jpeg，gif格式"
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "companyName", label: "公司名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { placeholder: "请输入公司名称" },
                        model: {
                          value: _vm.formData.companyName,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "companyName", $$v)
                          },
                          expression: "formData.companyName"
                        }
                      }),
                      _c("span", { staticClass: "form-tip" }, [
                        _vm._v(
                          "请按照营业执照上登记的完整名称填写，如有（），请在输入法为中文状态下输入 "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "unionCreditCode",
                        label: "统一社会信用代码："
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          placeholder: "请输入统一社会信用代码",
                          maxlength: "18"
                        },
                        model: {
                          value: _vm.formData.unionCreditCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "unionCreditCode", $$v)
                          },
                          expression: "formData.unionCreditCode"
                        }
                      }),
                      _c("span", { staticClass: "form-tip" }, [
                        _vm._v("请按照营业执照上的统一社会信用代码进行填写")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "businessLicenseProvince",
                        label: "营业执照所在地："
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "700px" } },
                        [
                          _c("d2-city", {
                            attrs: {
                              selfData: [
                                _vm.formData.businessLicenseProvince,
                                _vm.formData.businessLicenseCity,
                                _vm.formData.businessLicenseDistrict
                              ]
                            },
                            on: { change: _vm.cityChange }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "form-tip" }, [
                        _vm._v("请按照营业执照上的住所选择")
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "businessLicenseAddress", label: "住所：" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                          placeholder: "请输入住所"
                        },
                        model: {
                          value: _vm.formData.businessLicenseAddress,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              "businessLicenseAddress",
                              $$v
                            )
                          },
                          expression: "formData.businessLicenseAddress"
                        }
                      }),
                      _c("span", { staticClass: "form-tip" }, [
                        _vm._v("请按照营业执照上的住所填写")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "setupDate", label: "成立日期：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          placeholder: "请选择成立日期"
                        },
                        model: {
                          value: _vm.formData.setupDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "setupDate", $$v)
                          },
                          expression: "formData.setupDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "businessDateFrom", label: "营业期限：" }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.businessDateFrom },
                        model: {
                          value: _vm.formData.businessDateFrom,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "businessDateFrom", $$v)
                          },
                          expression: "formData.businessDateFrom"
                        }
                      }),
                      _c("span", { staticClass: "d2-pl d2-pr" }, [
                        _vm._v("至")
                      ]),
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          placeholder: "请选择"
                        },
                        model: {
                          value: _vm.formData.businessDateTo,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "businessDateTo", $$v)
                          },
                          expression: "formData.businessDateTo"
                        }
                      }),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "d2-ml",
                          on: { change: _vm.businessDateLongTime },
                          model: {
                            value: _vm.formData.businessDateLongTime,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "businessDateLongTime",
                                $$v
                              )
                            },
                            expression: "formData.businessDateLongTime"
                          }
                        },
                        [_vm._v("长期有效")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "registeredCapital", label: "注册资本：" }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "350px" },
                          attrs: { placeholder: "请输入内容" },
                          model: {
                            value: _vm.formData.registeredCapital,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "registeredCapital",
                                _vm._n($$v)
                              )
                            },
                            expression: "formData.registeredCapital"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v("万元")])],
                        2
                      ),
                      _c("span", { staticClass: "form-tip" }, [
                        _vm._v("若注册资本非人民币，按照当前汇率换算人民币填写")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "businessScope", label: "经营范围：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                          placeholder: "请输入经营范围"
                        },
                        model: {
                          value: _vm.formData.businessScope,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "businessScope", $$v)
                          },
                          expression: "formData.businessScope"
                        }
                      }),
                      _c("span", { staticClass: "form-tip" }, [
                        _vm._v("请与营业执照或企业信息公示网的经营范围保持一致")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "form-tit" }, [_vm._v("法人信息")]),
              _c(
                "el-form-item",
                {
                  staticClass: "d2-pt",
                  attrs: {
                    prop: "legalPersonIdCardFace",
                    label: "法人身份证件："
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { flex: "main" } },
                    [
                      _c(
                        "el-upload",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              "on-success": function(res) {
                                return _vm.handleSuccess(
                                  res,
                                  "legalPersonIdCardFace"
                                )
                              },
                              "before-upload": _vm.beforeUpload
                            }
                          },
                          "el-upload",
                          _vm.uploadData,
                          false
                        ),
                        [
                          _vm.formData.legalPersonIdCardFace
                            ? _c("el-image", {
                                staticClass: "avatar",
                                staticStyle: {
                                  width: "240px",
                                  height: "151px"
                                },
                                attrs: {
                                  fit: "cover",
                                  src: _vm.formData.legalPersonIdCardFace
                                }
                              })
                            : _c("div", [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon"
                                }),
                                _c(
                                  "span",
                                  { staticClass: "avatar-uploader-text" },
                                  [_vm._v("上传人面像")]
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "el-upload",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              "on-success": function(res) {
                                return _vm.handleSuccess(
                                  res,
                                  "legalPersonIdCardEmblem"
                                )
                              },
                              "before-upload": _vm.beforeUpload
                            }
                          },
                          "el-upload",
                          _vm.uploadData,
                          false
                        ),
                        [
                          _vm.formData.legalPersonIdCardEmblem
                            ? _c("el-image", {
                                staticClass: "avatar",
                                staticStyle: {
                                  width: "240px",
                                  height: "151px"
                                },
                                attrs: {
                                  fit: "cover",
                                  src: _vm.formData.legalPersonIdCardEmblem
                                }
                              })
                            : _c("div", [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon"
                                }),
                                _c(
                                  "span",
                                  { staticClass: "avatar-uploader-text" },
                                  [_vm._v("上传国徽面")]
                                )
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("span", { staticClass: "form-tip" }, [
                    _vm._v("文件大小在5MB以内，支持png，jpeg，gif格式")
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "legalPersonName", label: "法人姓名：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formData.legalPersonName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "legalPersonName", $$v)
                      },
                      expression: "formData.legalPersonName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "legalPersonIdCardNumber",
                    label: "法人证件号："
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入证件号", maxlength: "18" },
                    model: {
                      value: _vm.formData.legalPersonIdCardNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "legalPersonIdCardNumber", $$v)
                      },
                      expression: "formData.legalPersonIdCardNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "legalPersonIdCardTimeFrom",
                    label: "法人证件有效期："
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "请选择"
                    },
                    on: { change: _vm.legalPersonIdCardTimeFrom },
                    model: {
                      value: _vm.formData.legalPersonIdCardTimeFrom,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "legalPersonIdCardTimeFrom", $$v)
                      },
                      expression: "formData.legalPersonIdCardTimeFrom"
                    }
                  }),
                  _c("span", { staticClass: "d2-pl d2-pr" }, [_vm._v("至")]),
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "请选择"
                    },
                    model: {
                      value: _vm.formData.legalPersonIdCardTimeTo,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "legalPersonIdCardTimeTo", $$v)
                      },
                      expression: "formData.legalPersonIdCardTimeTo"
                    }
                  }),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "d2-ml",
                      on: { change: _vm.legalPersonIdCardLongTime },
                      model: {
                        value: _vm.formData.legalPersonIdCardLongTime,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formData,
                            "legalPersonIdCardLongTime",
                            $$v
                          )
                        },
                        expression: "formData.legalPersonIdCardLongTime"
                      }
                    },
                    [_vm._v("长期有效")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "form-tit" }, [_vm._v("经营类目")]),
              _c(
                "el-form-item",
                {
                  staticClass: "d2-pt",
                  attrs: { prop: "businessCategories", label: "经营类目：" }
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: { placement: "right", trigger: "click" },
                      on: { hide: _vm.hideCascader }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "500px" },
                          attrs: { multiple: "", placeholder: "请选择" },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.businessCategories,
                            callback: function($$v) {
                              _vm.businessCategories = $$v
                            },
                            expression: "businessCategories"
                          }
                        },
                        _vm._l(_vm.goodsTypesList, function(item) {
                          return _c("el-option", {
                            key: item.goodsTypeId,
                            attrs: {
                              label: item.typeName,
                              value: item.goodsTypeId
                            }
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            slot: "reference",
                            type: "primary",
                            icon: "el-icon-plus"
                          },
                          slot: "reference"
                        },
                        [_vm._v("新增类目")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "d2-mt-10",
                      staticStyle: { width: "800px" },
                      attrs: {
                        data: _vm.formData.businessCategories,
                        border: "",
                        "header-cell-style": { background: "#F6F6F6" }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsTypeName",
                          label: "经营类目",
                          width: "180",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var $index = ref.$index
                              var row = ref.row
                              return [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确定删除吗？" },
                                    on: {
                                      confirm: function($event) {
                                        return _vm.businessDel($index, row)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "text"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "form-tit" }, [_vm._v("税务信息")]),
              _c(
                "el-form-item",
                {
                  staticClass: "d2-pt",
                  attrs: { prop: "taxpayerType", label: "纳税人类型：" }
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formData.taxpayerType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "taxpayerType", $$v)
                        },
                        expression: "formData.taxpayerType"
                      }
                    },
                    [_vm._v("一般纳税人")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 2 },
                      model: {
                        value: _vm.formData.taxpayerType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "taxpayerType", $$v)
                        },
                        expression: "formData.taxpayerType"
                      }
                    },
                    [_vm._v("小规模纳税人")]
                  )
                ],
                1
              ),
              _vm.formData.taxpayerType == 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "d2-pt",
                      attrs: {
                        prop: "taxpayerCertImage",
                        label: "一般纳税人资格证："
                      }
                    },
                    [
                      _c(
                        "el-upload",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              "on-success": function(res) {
                                return _vm.handleSuccess(
                                  res,
                                  "taxpayerCertImage"
                                )
                              },
                              "before-upload": _vm.beforeUpload
                            }
                          },
                          "el-upload",
                          _vm.uploadData,
                          false
                        ),
                        [
                          _vm.formData.taxpayerCertImage
                            ? _c("el-image", {
                                staticClass: "avatar",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.formData.taxpayerCertImage
                                }
                              })
                            : _c("div", [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon"
                                })
                              ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { prop: "contractNo", label: "合同编号：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入合同编号" },
                    model: {
                      value: _vm.formData.contractNo,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "contractNo", $$v)
                      },
                      expression: "formData.contractNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "bankName", label: "开户行：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入开户行" },
                    model: {
                      value: _vm.formData.bankName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "bankName", $$v)
                      },
                      expression: "formData.bankName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "bankAccount", label: "账号：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入账号" },
                    model: {
                      value: _vm.formData.bankAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "bankAccount", $$v)
                      },
                      expression: "formData.bankAccount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "bankBranchName", label: "支行名称：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入支行名称" },
                    model: {
                      value: _vm.formData.bankBranchName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "bankBranchName", $$v)
                      },
                      expression: "formData.bankBranchName"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "form-tit" }, [_vm._v("店铺命名")]),
              _c(
                "el-form-item",
                {
                  staticClass: "d2-pt",
                  attrs: { prop: "storeName", label: "店铺名称：" }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入店铺名称" },
                    model: {
                      value: _vm.formData.storeName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "storeName", $$v)
                      },
                      expression: "formData.storeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "storeLogo", label: "店铺logo：" } },
                [
                  _c(
                    "el-upload",
                    _vm._b(
                      {
                        staticStyle: { display: "inline-block" },
                        attrs: {
                          "on-success": function(res) {
                            return _vm.handleSuccess(res, "storeLogo")
                          },
                          "before-upload": _vm.beforeUpload
                        }
                      },
                      "el-upload",
                      _vm.uploadData,
                      false
                    ),
                    [
                      _vm.formData.storeLogo
                        ? _c("el-image", {
                            staticClass: "avatar",
                            attrs: { fit: "cover", src: _vm.formData.storeLogo }
                          })
                        : _c("div", [
                            _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon"
                            })
                          ])
                    ],
                    1
                  ),
                  _c("span", { staticClass: "form-tip" }, [
                    _vm._v(
                      "图片尺寸要求为300*300的正方形图片，仅支持jpg.png和gif"
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "storeIntro", label: "店铺简介：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      placeholder: "请输入店铺简介",
                      type: "textarea",
                      "show-word-limit": "",
                      maxlength: 100,
                      autosize: { minRows: 3, maxRows: 5 }
                    },
                    model: {
                      value: _vm.formData.storeIntro,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "storeIntro", $$v)
                      },
                      expression: "formData.storeIntro"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-radio",
                {
                  staticClass: "d2-pl",
                  staticStyle: { block: "block" },
                  attrs: { label: "1" },
                  model: {
                    value: _vm.radioBlock,
                    callback: function($$v) {
                      _vm.radioBlock = $$v
                    },
                    expression: "radioBlock"
                  }
                },
                [
                  _vm._v("我已阅读并同意"),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#409eff" },
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = true
                        }
                      }
                    },
                    [_vm._v("《要炼入驻协议》")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "d2-ml d2-mt el-btn",
              attrs: { type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("提交入驻申请")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { width: "60%", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              _vm.dialogFormVisible = false
            }
          }
        },
        [
          _c("settleAgreement", {
            attrs: {
              companyName: _vm.formData.companyName,
              storeName: _vm.formData.storeName
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("div", { staticClass: "page-header-title" }, [_vm._v("入驻申请")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }