import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
import utilCity from '@/libs/city.json';
import { cloneDeep } from 'lodash';
export default {
  props: {
    layer: {
      type: Object,
      default: function _default() {
        return {
          show: false
        };
      }
    }
  },
  data: function data() {
    return {
      checked: false,
      isIndeterminate: true
    };
  },
  computed: {
    utilCityData: function utilCityData() {
      var arr = utilCity.map(function (v) {
        v.children.forEach(function (k) {
          return delete k.children;
        });
        return v;
      });
      return arr;
    }
  },
  methods: {
    confirm: function confirm() {
      var getCheckedKeys = this.$refs.tree.getCheckedKeys();
      var getHalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
      var arrKeys = getHalfCheckedKeys.concat(getCheckedKeys);
      var data = [];
      this.utilCityData.forEach(function (v) {
        arrKeys.forEach(function (pre, cur) {
          if (pre == v.value && v.children && v.children.length > 0) {
            var children = [];
            v.children.forEach(function (d) {
              return arrKeys.forEach(function (k) {
                return k == d.value && children.push(d);
              });
            });
            var bool = children.length == v.children.length;
            data.push(cloneDeep(_objectSpread(_objectSpread({}, v), {}, {
              children: children,
              allCity: bool
            })));
          }
        });
      });
      this.$emit('confirm', data);
    },
    getRefTree: function getRefTree(keys) {
      var _this = this;

      setTimeout(function () {
        return _this.$refs.tree.setCheckedKeys(keys);
      }, 100);
    }
  }
};