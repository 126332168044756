var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "el-card",
        {
          staticClass: "d2-pb",
          attrs: { shadow: "never", "body-style": { padding: "10px" } }
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never", "body-style": { padding: "10px" } } },
            [
              _c("div", [_c("b", [_vm._v("1.确认订单信息")])]),
              _c("div", { staticClass: "number" }, [
                _c(
                  "div",
                  { staticStyle: { "margin-right": "50px" } },
                  [
                    _c("label", [_vm._v("订单号：")]),
                    _c("b", [_vm._v(_vm._s(_vm.detail.orderNo))]),
                    _c(
                      "el-button",
                      { attrs: { type: "text" }, on: { click: _vm.copyThat } },
                      [_vm._v("复制")]
                    )
                  ],
                  1
                ),
                _c("div", [
                  _c("label", [_vm._v("下单时间：")]),
                  _c("span", [_vm._v(_vm._s(_vm.detail.orderTime))])
                ])
              ]),
              _c(
                "div",
                { staticClass: "order-detail" },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.detail.goodsItemList,
                        "header-cell-style": { background: "#F6F6F6" }
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "goodsName", label: "商品名称" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsSku",
                          label: "SKU",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsPrice",
                          label: "单价",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "goodsCount",
                          label: "数量",
                          align: "center"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "table-footer" }, [
                    _c("div", [
                      _c("label", [_vm._v("订单金额：")]),
                      _c("span", [
                        _vm._v("￥ " + _vm._s(_vm.detail.orderAmount))
                      ]),
                      _vm._v(
                        " (含运费￥" + _vm._s(_vm.detail.deliveryAmount) + ") "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "table-footer__desc" }, [
                _c("div", [
                  _c("label", [_vm._v("收货地址：")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.detail.consigneeInfo.consigneeName +
                          " " +
                          _vm.detail.consigneeInfo.consigneePhone +
                          " "
                      ) +
                        _vm._s(
                          _vm._f("consigneeInfo")(_vm.detail.consigneeInfo)
                        )
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "remake" }, [
                _c("div", { staticClass: "card" }, [
                  _c("label", [_vm._v("买家留言")]),
                  _c("p", [_vm._v(_vm._s(_vm.detail.buyerRemark || "无"))])
                ]),
                _c(
                  "div",
                  { staticClass: "card" },
                  [
                    _c("label", [_vm._v("商家留言")]),
                    _c("p", [_vm._v(_vm._s(_vm.detail.storeRemark || "无"))]),
                    _vm.isShow("store-order-remark")
                      ? _c(
                          "el-button",
                          {
                            staticClass: "card-btn",
                            attrs: { type: "text" },
                            on: { click: _vm.editRemark }
                          },
                          [_vm._v("修改备注")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "card d2-mt" },
                [
                  _c(
                    "el-descriptions",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [_c("p", [_vm._v("发票信息")])]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "发票抬头" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.invoiceInfo
                                ? _vm.detail.invoiceInfo.invoiceTitle
                                : ""
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "纳税人识别号" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.invoiceInfo
                                ? _vm.detail.invoiceInfo.taxpayerIdNumber
                                : ""
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "注册地址" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.invoiceInfo
                                ? _vm.detail.invoiceInfo.taxpayerAddress
                                : ""
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "开户银行" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.invoiceInfo
                                ? _vm.detail.invoiceInfo.invoiceBankName
                                : ""
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "银行账号" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.invoiceInfo
                                ? _vm.detail.invoiceInfo.invoiceBankAccount
                                : ""
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "center d2-p-10" }, [
            _c("div", [_c("b", [_vm._v("2.确认出库信息")])]),
            _c(
              "div",
              { staticClass: "card d2-mt-10 table-footer__desc" },
              [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("deliveryAddress")(_vm.detail.deliveryAddress)
                      )
                    )
                  ])
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.getAddressList()
                      }
                    }
                  },
                  [_vm._v("修改出库信息")]
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "center d2-p-10" },
            [
              _c("div", [_c("b", [_vm._v("3.填写物流信息")])]),
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "card d2-mt-10" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { label: "物流公司：", prop: "expressCompany" }
                        },
                        [
                          _c("base-select", {
                            attrs: { options: _vm.expressList },
                            model: {
                              value: _vm.formData.expressCompany,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "expressCompany", $$v)
                              },
                              expression: "formData.expressCompany"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "快递单号：", prop: "expressNumber" }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            model: {
                              value: _vm.formData.expressNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "expressNumber", $$v)
                              },
                              expression: "formData.expressNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "footer-btn",
                      staticStyle: { "margin-left": "80px" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          on: {
                            click: function($event) {
                              return _vm.goBack()
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.submin }
                        },
                        [_vm._v("确认出库")]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer }, on: { confirm: _vm.confirm } },
        [
          _c("div", { staticClass: "tip" }, [
            _c("i", { staticClass: "el-icon-info" }),
            _c("span", [
              _vm._v("请核对订单后，再修改收货地址（暂且仅支持修改一次）")
            ])
          ]),
          _c(
            "el-form",
            {
              ref: "layerForm",
              attrs: { model: _vm.layer.form, "label-width": "120px" }
            },
            [
              _c("el-form-item", { attrs: { label: "订单号" } }, [
                _c(
                  "p",
                  { staticStyle: { color: "#ff873a", "font-weight": "900" } },
                  [_vm._v(_vm._s(_vm.active.orderNo))]
                )
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "客户姓名", prop: "consigneeName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.layer.form.consigneeName,
                      callback: function($$v) {
                        _vm.$set(_vm.layer.form, "consigneeName", $$v)
                      },
                      expression: "layer.form.consigneeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所在地区" } },
                [
                  _c("d2-city", {
                    attrs: { selfData: _vm.selfDataCity },
                    on: { change: _vm.cityChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "详细地址", prop: "consigneeDetails" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.layer.form.consigneeDetails,
                      callback: function($$v) {
                        _vm.$set(_vm.layer.form, "consigneeDetails", $$v)
                      },
                      expression: "layer.form.consigneeDetails"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "consigneePhone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.layer.form.consigneePhone,
                      callback: function($$v) {
                        _vm.$set(_vm.layer.form, "consigneePhone", $$v)
                      },
                      expression: "layer.form.consigneePhone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "固定电话", prop: "consigneeTel" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.layer.form.consigneeTel,
                      callback: function($$v) {
                        _vm.$set(_vm.layer.form, "consigneeTel", $$v)
                      },
                      expression: "layer.form.consigneeTel"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer2 }, on: { confirm: _vm.clickOpenStay } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.detail.deliveryAddressId,
                callback: function($$v) {
                  _vm.$set(_vm.detail, "deliveryAddressId", $$v)
                },
                expression: "detail.deliveryAddressId"
              }
            },
            _vm._l(_vm.layer2.options, function(item) {
              return _c("el-option", {
                key: item.addressId,
                attrs: {
                  label: _vm._f("deliveryAddress")(item),
                  value: item.addressId
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }