var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-class" }, [
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("要炼商城入驻协议")
    ]),
    _c("p", { staticStyle: { "text-decoration": "underline" } }, [
      _vm._v("要炼健康科技（深圳）有限公司（“要炼”）")
    ]),
    _c("p", { staticStyle: { "text-decoration": "underline" } }, [
      _vm._v(_vm._s(_vm.companyName) + "有限公司 （“商家”）")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "本协议由“要炼”平台的技术服务提供方“要炼健康科技（深圳）有限公司”代表“要炼”与拟使用要炼商城服务的法律实体，在自愿、平等、公平及诚实信用原则的基础上，根据《中华人民共和国民法典》、《中华人民共和国电子商务法》、《中华人民共和国产品质量法》和《中华人民共和国消费者权益保护法》等相关法律、法规的规定，经友好协商缔结。"
      )
    ]),
    _c("h4", [_vm._v("1. 定义")]),
    _vm._m(0),
    _c("p", [
      _vm._v(
        "1.2 商家：指在要炼平台以独立第三方经营者身份设立店铺进行经营商品或服务的法律实体。"
      )
    ]),
    _c("p", [
      _vm._v(
        "1.3 店铺：指商家在要炼平台创建的虚拟运营单位。商家对其创建和经营的店铺承担全部责任。商家创建的店铺与在要炼创建的社区账号自动绑定，并承担对账号操作的一切责任。"
      )
    ]),
    _c("p", [_vm._v("1.4 消费者：指通过要炼平台购买商家商品的购买人。")]),
    _c("p", [
      _vm._v("1.5 商品：指商家在要炼平台店铺展示并供消费者选购的产品。")
    ]),
    _c("p", [
      _vm._v(
        "1.6 商品信息：指所有商品的信息，包括产品名称、型号、规格、款式、颜色、描述、图片及其他商家应当向要炼提供的与商品有关的信息和资料。"
      )
    ]),
    _c("p", [
      _vm._v(
        "1.7 管理规则：指由要炼制订规范商家入驻店铺在要炼平台经营行为的已发布及后续发布的全部规则、实施细则、解读、产品流程说明、公告等，视为本合同的有效补充。商家有义务在商家管理后台及时阅读相关规则，要炼有权不定期修改或更新管理规则的内容，入驻店铺必须严格遵照执行。"
      )
    ]),
    _c("p", [
      _vm._v(
        "1.8 保证金：指商家为了保证其对本协议的履行，向要炼支付的履约保证金。"
      )
    ]),
    _c("p", [
      _vm._v(
        "1.9 不可抗力：指洪水、地震和台风等严重自然灾害、传染病、战争、敌对封锁、政府命令、黑客攻击、通讯故障等当事人不能预见、不能避免且不能克服的事件。"
      )
    ]),
    _c("p", [
      _vm._v(
        "1.10 秘密信息：指履行本协议过程中，一方（披露方）向对方（接收方），以书面或以口头方式，披露的文件及信息，包括但不限于披露方的客户、市场、技术、价格、产品、供应商、协议条款、发展计划等信息，以及任何有关本协议，本协议的各项条件及条款，本协议下所有附录，本协议下的潜在交易（包括其存在性）的信息。未取得披露方书面同意之前，任何一方不得将上述信息透露给第三方（有关法律、法规、政府部门、证券交易所或其他监管机构要求披露的除外），或用于本协议所述服务范围以外的用途。"
      )
    ]),
    _c("h4", [_vm._v("2. 合作模式")]),
    _c("p", [
      _vm._v("2.1 商家在要炼创建店铺，店铺名称："),
      _c("span", { staticStyle: { "text-decoration": "underline" } }, [
        _vm._v(_vm._s(_vm.storeName))
      ]),
      _vm._v(
        "（以下称“入驻店铺”） 。入驻店铺名称可能会依据平台要求等发生变更，为避免疑问，入驻店铺名称的变更不影响本协议的履行，双方确认以店铺ID作为店铺的识别依据。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.2 本协议约定的合作条件包括平台服务费费率、付款周期或优惠政策等仅适用于2.1条约定的入驻店铺，商家在要炼平台拥有多家店铺的，本协议约定的合作条件和优惠政策等不适用于商家的其他店铺。"
      )
    ]),
    _c("p", [
      _vm._v("2.3 要炼向商家提供互联网信息服务、软件技术服务和营销推广服务：")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "a.展现商品、关联相关商品、搜索商品、生成订单、管理交易、完成支付和平台客服的软件系统；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "b.依据商家经营及活动参与情况，提高商品曝光机会、品牌整合营销、销售提升、运营培训等品牌增值服务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.4 商家通过其在要炼平台的入驻店铺展示待售商品，供消费者浏览和选择，消费者下达订单并付款后，由商家通过要炼平台确认订单。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.5 商家收到消费者订单后，将商品和发货单以双方确认的包装标准打包，使用双方确认的物流方式将商品交付消费者。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.6 要炼及其相关平台均不享有对商品的所有权，也不承担商品毁损灭失的风险。 "
      )
    ]),
    _c("h4", [_vm._v("3. 入驻条件")]),
    _c("p", [_vm._v("3.1 商家入驻要炼平台应当满足以下全部条件：")]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("a.符合法律法规及要炼的入驻标准；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("b.接受并严格履行本协议及管理规则；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("c.遵守对消费者的陈述和保证（见附件一）。")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("d.具备合法的权利能力和行为能力签署本协议并使用要炼相关服务。")
    ]),
    _c("p", [
      _vm._v(
        "3.2 要炼有权制定，并不时修改入驻标准，相关入驻标准的新增及变更要炼将提前至少7日进行公示。针对入驻标准变更生效前已开通服务的商家，要炼将给予一定的合理期限，以便商家在合理期限届满前确保满足当前的要炼入驻标准。如合理期限届满时商家仍无法满足的，要炼有权在合理期限届满时解除本协议。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.3 商家承诺向要炼提供的证明文件和资质文件都是真实的、合法的和有效的。如商家从事店铺经营依法需要取得相关行政许可的，应当依法取得行政许可。一旦文件内容发生变动、文件效力发生变化，商家承诺24小时内以书面方式通知要炼。若商家提供的前述文件是伪造的，或文件内容变动商家未在规定时间内通知要炼的，要炼有权冻结店铺，下架商品并按平台管理规则及法律规定进行处罚。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.4 要炼对于商家提供与入驻店铺相关的证明文件和资质文件，有权进一步核实，商家应当积极配合要炼。要炼有权根据自己的判断决定是否继续与商家合作。"
      )
    ]),
    _c("h4", [_vm._v("4. 运营管理")]),
    _c("p", [
      _vm._v(
        "4.1 商家应当根据管理规则要求为入驻店铺创建待售商品，并且根据管理规则如实填写与商品有关的所有必要信息。商品的信息包括但不限于商品名称、条形码、品牌、颜色、型号、规格、零售价、原产国、图片和产品描述。商家应当全面、真实、准确、及时地披露商品或者服务信息，保障消费者的知情权和选择权。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.2 无论已经创建的商品还是新创建的商品，要炼均有权根据自身的营销政策和经营状况决定是否同意该商品在要炼平台销售。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.3 商家有权根据自身的经营状况和库存状况决定对入驻店铺的商品实施上架或下架。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.4 商家应当实时向要炼平台更新待售商品的库存状态，对于显示有货的商品应当尽到最大努力履行消费者的订单。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.5 商家不得以虚构交易、编造用户评价等方式进行虚假或者引人误解的商业宣传，欺骗、误导消费者。"
      )
    ]),
    _c("p", [_vm._v("4.6 商家应向消费者开具符合法律法规的发票。")]),
    _c("p", [
      _vm._v(
        "4.7 商家销售的商品或者提供的服务应当符合保障人身、财产安全的要求和环境保护要求，不得销售或者提供法律、行政法规禁止交易的商品或者服务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.8 为履行相关法律法规义务、保障消费者权益及维护平台秩序及平台运营需要等目的，要炼有权对商家的商品、交易及店铺等数据进行查阅、分析。因网上交易平台具备海量信息及信息网络环境下信息与实物相分离等特殊性，要炼无法逐一审查商品及/或服务，除法律有明确规定外，要炼无需对所有商家的交易行为以及与交易有关的其它事项进行事先审查，但若经消费者、监管部门、权利人等第三方通知要炼或要炼基于对商品、交易或店铺等多维度大数据综合分析发现商家行为及/或具体交易事项、具体信息可能违反相关法律法规、本协议的，要炼有权以大众一般认知的知识水平标准对相关内容进行的合理判别，要炼可根据不同情况向商家发出询问或要求改正的通知，或直接作出删除相关信息、对商家店铺采取限制性措施或停止对商家提供服务等处理。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.9 要炼有权基于法律法规、商品品质控制需求及管理规则对商家在售商品进行质量抽检，检测报告由专业的第三方质检机构出具，商家承诺对该第三方质检机构出具的检测结果不持有异议并承担所有后果。对于经检测证明存在质量问题的商品，检测费用由商家承担，且要炼有权采取包括下架商品、冻结店铺等措施。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.10 商家授权要炼，在消费者对商品提出异议、投诉后，要炼有权以入驻店铺的名义代表商家与消费者处理纠纷，涉及的赔偿、补偿等由商家承担。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.11 商家应严格遵守网络安全与数据保护相关法律的要求，其中，商家在店铺经营过程中收集、使用及共享的信息中涉及用户个人信息的，须遵循合法、正当、必要原则，不得违反相关法律法规的规定及商家与用户之间的约定，包括但不限于：在无用户其他授权的情况下，商家不得将用户的订单信息等与交易有关的必要信息用于《要炼用户隐私协议》中约定的为满足用户购买商品或服务、完成售后服务以外的目的；禁止未经用户明确同意或超出用户授权范围使用其信息或非法向他人提供用户个人信息等。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.12 商家应当采取必要的技术措施保障在店铺经营过程中收集、使用的用户个人信息及其他数据的安全。如发生或可能发生信息安全风险（如消费者信息泄露、诈骗事件）的，商家应在发现或可能发现信息安全风险时起24小时内通知要炼并立即采取补救措施。商家还应依据法律规定及时告知用户并向有关主管部门报告。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.13 如商家在店铺经营过程中与第三方服务商合作为用户提供服务的，商家应与第三方服务商签署保密协议，以保证“要炼”消费者的个人信息安全。如商家使用第三方ERP系统管理交易订单的，还应向要炼报备第三方ERP系统厂商信息及其数据安全能力。"
      )
    ]),
    _c("h4", [_vm._v("5. 违约责任")]),
    _c("p", [
      _vm._v(
        "5.1 商家在入驻店铺发布的任何信息都不得违反《广告法》（包括但不限于使用“第一”、“最高”、“最好”、“国家级”、“首选”、“驰名商标”等）、《消费者权益保护法》、《网络交易监督管理办法》和《禁止价格欺诈行为的规定》等国家、地方法律法规的规定。"
      )
    ]),
    _c("p", [
      _vm._v(
        "5.2 因商家行为导致要炼遭受任何第三方提起的索赔、诉讼或行政责任，商家将承担全部责任并使要炼免责。因要炼应对诉讼或行政责任产生的实际费用由商家承担。如因商家行为导致要炼受有其他损失的，商家应当承担赔偿责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "5.3 本协议约定的违约金不足以补偿守约方损失的，守约方有权向违约方进一步追偿。"
      )
    ]),
    _c("p", [
      _vm._v(
        "5.4 商家对其入驻要炼的所有店铺承担法律责任。商家违反本协议约定的义务，要炼有权对其店铺采取收取罚款、违约金或赔偿金，下架商品和关闭店铺、冻结货款等措施，具体措施以要炼管理规则及通知商家的标准为准。"
      )
    ]),
    _c("h4", [_vm._v("6. 协议解除")]),
    _c("p", [
      _vm._v(
        "6.1 商家违反法律法规、本协议约定及其陈述和保证、要炼管理规则的，要炼可解除本协议。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.2 任一方可提前30天通知对方解除本协议。如商家依据本条款约定通知解除本协议的，须按照相关规则向要炼提出申请。商家的店铺进入关店程序的，需进行店铺退出公示（店铺退出公示，是指在一定时间内商户店铺首页显示该店铺即将终止经营的公告信息）。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.3 商家创建的任何一个店铺出现下列情形之一的，要炼有权立即解除本协议并关闭商家在要炼创建的所有店铺："
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("a)商家不再满足入驻要炼平台的条件；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("b)商家使用或向要炼提交虚假、过期、失效的证书或资质文件；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("c)商家在售商品非原厂正品或商品质量不符合中国法律规定；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "d)商家在售商品侵犯任何第三方的合法权益，包括知识产权、商业秘密等；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("e)商家采取不正当竞争的方式扰乱正常的交易秩序；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("f)商家泄露要炼的商业秘密或侵犯要炼的知识产权；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "g)商家泄露消费者的个人信息或违反本协议第4.11条不正当使用消费者的个人信息；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "h)商家违反第4.12条的约定，未采取必要的技术措施保障信息安全或出现本协议第4.12条的信息安全风险时，商家没有按约定时间及时通知要炼；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "i)如商家使用第三方ERP系统管理订单且未按照本协议约定向要炼报备的；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("j)商家丧失主体资格，企业注销或被吊销营业执照的；")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "k)其他违反管理规则达到解除条件及严重违反本协议或侵犯消费者权益的行为。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.4 本协议无论何种原因终止或解除后，已经生效的订单，商家仍应积极履行并妥善处置。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.5 如商家丧失主体资格的，应在丧失主体资格前60个工作日通知要炼，以便要炼在协议终止或解除后完成剩余销售款和保证金的结算。如商家未能按约定通知，商家或其权利义务继受人应当向要炼提供充分证据以便于要炼完成结算，否则要炼有权依据相关法律法规的规定不予结算。如因此给要炼造成任何损失的，该权利义务继受人应当赔偿要炼的全部损失。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.6 本协议终止或解除后，双方应于终止或解除之日起三个月内完成剩余销售款和保证金的结算，要炼应在结算完成后将应付结算款支付商家。应付结算款＝剩余应付销售款＋剩余保证金－赔偿款－其他应付未付款项。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.7 协议终止或解除不影响任何一方对因对方违约或侵权等行为造成的损失进行索赔的权利。"
      )
    ]),
    _c("h4", [_vm._v("7. 知识产权及保密")]),
    _c("p", [
      _vm._v(
        "7.1 任何一方对在履行本协议过程中所获知的对方的秘密信息负有保密义务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7.2 任何一方违反保密义务，守约方有权立即解除本协议。违约方应当向守约方赔偿因此造成的全部损失。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7.3 要炼平台在相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于要炼所有。要炼提供相关服务时所依托的软件的著作权、专利权及其他知识产权均归要炼所有。未经要炼许可，商家不得擅自使用（包括但不限于通过任何机器人、“蜘蛛”等程序或设备监视、复制、传播、展示、镜像、上载、下载）“要炼”软件及相关服务中的内容。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7.4 商家承诺，在使用要炼平台及相关服务时发布上传的内容（包括但不限于商品、文字、图片、视频、音频等各种形式的内容及其中包含的音乐、声音、台词、视觉设计等所有组成部分）均由商家原创或已获合法授权（且含转授权），不存在侵犯要炼以及其他任何第三方已有合法权益的情形，通过要炼平台销售商品的行为也不会侵犯要炼以及任何第三方的合法权益。商家通过要炼平台发布所产生内容的知识产权归属商家或原始著作权人所有。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7.5 要炼为要炼平台开发、运营提供技术支持，并对要炼平台及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利，要炼有权针对商家运营过程中产生的交易数据进行各类数据分析。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7.6 在本协议终止之后，本协议约定的保密义务继续有效，双方仍需遵守本协议之保密条款，履行其所承诺的保密义务。"
      )
    ]),
    _c("h4", [_vm._v("8. 不可抗力")]),
    _c("p", [
      _vm._v(
        "8.1 受不可抗力事件影响无法全部或部分履行协议的一方应以合理方式立即将不可抗力事件及其影响通知对方，并在不可抗力事件发生后15日内向对方提供能够证实不可抗力发生的政府、公证机关或商会出具的证明或权威媒体的报道。怠于通知而造成对方损失或损失扩大的，应承担赔偿责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8.2 发生不可抗力，任何一方当事人因不可抗力而不能履行或延迟履行约定义务的，不承担违约责任。经协议双方协商一致后可以延迟、中止或终止履行本协议。"
      )
    ]),
    _c("h4", [_vm._v("9. 协议期限")]),
    _c("p", [
      _vm._v(
        "9.1 本协议自签订日开始，有效期为一年，有效期满后，要炼将推送续签信息，请及时查看并续签。"
      )
    ]),
    _c("p", [
      _vm._v(
        "9.2 任何一方当事人有意延续合作的，应在本协议有效期限届满30日前，以书面形式通知对方当事人，经双方协商一致后重新签署协议或者书面延续本协议。"
      )
    ]),
    _c("h4", [_vm._v("10. 其它条款")]),
    _c("p", [
      _vm._v(
        "10.1 未经要炼先书面同意，商家不得将本协议的权利或义务部分或全部转让或分包给任何第三方。要炼有权将本协议项下的权利义务全部或部分转让或分包予要炼关联方，并至少提前7个工作日通知商家。转让或分包的通知自送达商家之日起生效。"
      )
    ]),
    _c("p", [_vm._v("10.2 要炼保留对本协议的任何修改或补充的权利")]),
    _c("p", [_vm._v("10.3 本协议适用中华人民共和国现行有效的法律法规。")]),
    _c("p", [
      _vm._v(
        "10.4 因履行本协议发生的争议，应友好协商解决。协商解决不成的，任何一方均可向深圳市龙岗区人民法院提起诉讼。"
      )
    ]),
    _c("h4", [_vm._v("11. 协议附件")]),
    _c("p", [_vm._v("11.1 附件1 陈述和保证")]),
    _c("p", [_vm._v("11.2 附件2 法律法规禁止使用的词语举例")]),
    _c("h2", [_vm._v("附件一：")]),
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("陈述和保证")
    ]),
    _c("p", [_vm._v("致消费者和要炼平台：")]),
    _c("p", [
      _vm._v("我们作为要炼平台的入驻商家，针对所售商品做出如下陈述和保证：")
    ]),
    _c("p", [
      _vm._v(
        "1. 我们承诺提供真实、合法、准确、有效的注册资料，并保证电子邮件地址、联系电话、联系地址、邮政编码等内容的有效性及安全性，保证要炼及消费者可以通过上述联系方式与我们进行联系。同时，当相关资料实际变更时及时更新有关注册资料。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2. 我们承诺遵守与要炼签订的协议，遵守要炼制定的管理规则以及所有公示于要炼的规则和流程。"
      )
    ]),
    _c("p", [
      _vm._v("3. 我们已经取得一切必须且合法的权利销售商品，具有合法的销售权。")
    ]),
    _c("p", [
      _vm._v("4. 我们发布于要炼的所有信息真实、准确，符合中国大陆法律法规。")
    ]),
    _c("p", [
      _vm._v(
        "5. 我们承诺所售商品将按照不低于《产品质量法》、《消费者权益保护法》及其他法规、规章和中国强制性标准的要求，并提供售后服务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6. 我们承诺对售出商品按照消费者实际支付的现金金额开具适格发票，并承担因此产生的税收。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7. 我们保证在使用服务进行交易的过程中遵守诚实信用的原则，不在销售过程中采取不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8. 我们保证遵守中国的法律、法规和要炼的相关规则、规定，我们尊重中国的公共利益、公共道德和善良风俗。"
      )
    ]),
    _c("p", [
      _vm._v(
        "9.我们保证采取一切必要的手段保护消费者的隐私，不会对消费者的信息做出作商业性利用。"
      )
    ]),
    _c("p", [
      _vm._v(
        "10.我们承诺拥有合法的权利和资格向要炼提供商品信息，我们提供的商品信息未侵犯任何第三方合法权益，包括但不限于第三方知识产权和商业秘密。"
      )
    ]),
    _c("p", [
      _vm._v("11. 我们承诺不在商品中使用任何未获授权品牌的名称、关键字或图形。")
    ]),
    _c("p", [
      _vm._v(
        "12. 我们承诺对要炼平台在售商品信息如实描述，对描述的商品信息负有完全的举证责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "13. 我们承诺遵守要炼平台制订的退货政策，在消费者选择退货的情况下，积极主动配合消费者完成退货。"
      )
    ]),
    _c("p", [
      _vm._v(
        "14. 我们承诺所有在售商品都是原厂正品不存在任何假冒、翻新、改装、二次包装等任何与原厂出售商品存在差异的情况。"
      )
    ]),
    _c("h2", [_vm._v("附件二: ")]),
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("法律法规禁止使用的词语举例")
    ]),
    _c("p", [
      _vm._v(
        "本附件仅举例说明法律法规禁止使用的词语，商家有义务了解法律法规的具体规定，避免违反相关规定的行为出现。"
      )
    ]),
    _c("p", [_vm._v("违反《广告法》最多的三大类：")]),
    _c("p", [_vm._v("1. 顶级绝对化用语（最，第一，唯一等，涉及所有品类）；")]),
    _c("p", [_vm._v("2. 虚假广告；")]),
    _c("p", [
      _vm._v(
        "3. 非药宣传医疗/疾病治疗功能/保健疗效/声称或者暗示广告商品为保障健康所必需。"
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "1.1 要炼：指“要炼”平台的所有者“要炼健康科技（广州）有限公司”，包括网址为"
      ),
      _c(
        "a",
        {
          staticStyle: { color: "#409eff" },
          attrs: { href: "https://yaolianfit.com", target: "blank" }
        },
        [_vm._v("yaolianfit.com")]
      ),
      _vm._v(" 的网站和名称为 “ 要炼 ” 的移动客户端。")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }