var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "d2-p-10 d2-pt-20",
      attrs: { shadow: "always", "body-style": { padding: 0, maring: 0 } }
    },
    [
      _c(
        "div",
        {
          staticClass: "tit-box d2-p-10 d2-pl-20",
          attrs: { flex: "main:left cross:center" }
        },
        [
          _c("b", { staticClass: "d2-pr" }, [_vm._v("营业执照")]),
          _c(
            "span",
            {
              staticStyle: { color: "#02a7f0", cursor: "pointer" },
              on: {
                click: function($event) {
                  return _vm.clickEdit("1")
                }
              }
            },
            [_vm._v(_vm._s(_vm.yingye.edit1 ? "保存" : "修改"))]
          )
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "d2-p",
          staticStyle: { width: "50%" },
          attrs: {
            model: _vm.businessLicenseForm,
            rules: _vm.yingye.rules,
            "label-position": "right",
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "营业执照图片：" } },
            [
              _vm.yingyeEdit1
                ? _c(
                    "el-upload",
                    _vm._b(
                      {
                        attrs: {
                          "on-success": function(res) {
                            return _vm.handleSuccess(
                              res,
                              "businessLicenseImage",
                              _vm.businessLicenseForm
                            )
                          },
                          "before-upload": _vm.beforeUpload
                        }
                      },
                      "el-upload",
                      _vm.uploadData,
                      false
                    ),
                    [
                      _vm.businessLicenseForm.businessLicenseImage
                        ? _c("el-image", {
                            staticClass: "avatar",
                            attrs: {
                              fit: "cover",
                              src: _vm.businessLicenseForm.businessLicenseImage
                            }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ],
                    1
                  )
                : _c("el-image", {
                    staticClass: "el-image",
                    attrs: {
                      src: _vm.yingye.form.businessLicenseImage,
                      fit: "cover",
                      "preview-src-list": [_vm.yingye.form.businessLicenseImage]
                    }
                  })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司名称：" } },
            [
              _vm.yingyeEdit1
                ? _c("el-input", {
                    model: {
                      value: _vm.businessLicenseForm.companyName,
                      callback: function($$v) {
                        _vm.$set(_vm.businessLicenseForm, "companyName", $$v)
                      },
                      expression: "businessLicenseForm.companyName"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.companyName))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "统一社会信用代码：" } },
            [
              _vm.yingyeEdit1
                ? _c("el-input", {
                    model: {
                      value: _vm.businessLicenseForm.unionCreditCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.businessLicenseForm,
                          "unionCreditCode",
                          $$v
                        )
                      },
                      expression: "businessLicenseForm.unionCreditCode"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.unionCreditCode))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "营业执照所在地：" } },
            [
              _vm.yingyeEdit1
                ? _c("d2-city", {
                    attrs: {
                      selfData: [
                        _vm.businessLicenseForm.businessLicenseProvince,
                        _vm.businessLicenseForm.businessLicenseCity,
                        _vm.businessLicenseForm.businessLicenseDistrict
                      ]
                    },
                    on: { change: _vm.cityChange }
                  })
                : _c("span", [
                    _vm._v(
                      _vm._s(_vm.yingye.form.businessLicenseProvince) +
                        "/" +
                        _vm._s(_vm.yingye.form.businessLicenseCity) +
                        "/" +
                        _vm._s(_vm.yingye.form.businessLicenseDistrict)
                    )
                  ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "住所：" } },
            [
              _vm.yingyeEdit1
                ? _c("el-input", {
                    model: {
                      value: _vm.businessLicenseForm.businessLicenseAddress,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.businessLicenseForm,
                          "businessLicenseAddress",
                          $$v
                        )
                      },
                      expression: "businessLicenseForm.businessLicenseAddress"
                    }
                  })
                : _c("span", [
                    _vm._v(_vm._s(_vm.yingye.form.businessLicenseAddress))
                  ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "成立日期：" } },
            [
              _vm.yingyeEdit1
                ? _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期"
                    },
                    model: {
                      value: _vm.businessLicenseForm.setupDate,
                      callback: function($$v) {
                        _vm.$set(_vm.businessLicenseForm, "setupDate", $$v)
                      },
                      expression: "businessLicenseForm.setupDate"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.setupDate))])
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "营业期限：" } }, [
            _vm.yingyeEdit1
              ? _c(
                  "div",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        type: "date",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.businessLicenseForm.businessDateFrom,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.businessLicenseForm,
                            "businessDateFrom",
                            $$v
                          )
                        },
                        expression: "businessLicenseForm.businessDateFrom"
                      }
                    }),
                    _c("span", { staticClass: "d2-pl d2-pr" }, [_vm._v("至")]),
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        type: "date",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.businessLicenseForm.businessDateTo,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.businessLicenseForm,
                            "businessDateTo",
                            $$v
                          )
                        },
                        expression: "businessLicenseForm.businessDateTo"
                      }
                    }),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "d2-ml",
                        model: {
                          value: _vm.businessLicenseForm.businessDateLongTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.businessLicenseForm,
                              "businessDateLongTime",
                              $$v
                            )
                          },
                          expression: "businessLicenseForm.businessDateLongTime"
                        }
                      },
                      [_vm._v("长期有效")]
                    )
                  ],
                  1
                )
              : _c("span", [
                  _vm._v(
                    _vm._s(_vm.yingye.form.businessDateFrom) +
                      " 至 " +
                      _vm._s(_vm.yingye.form.businessDateTo)
                  )
                ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "注册资本：" } },
            [
              _vm.yingyeEdit1
                ? _c(
                    "el-input",
                    {
                      model: {
                        value: _vm.businessLicenseForm.registeredCapital,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.businessLicenseForm,
                            "registeredCapital",
                            _vm._n($$v)
                          )
                        },
                        expression: "businessLicenseForm.registeredCapital"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("万元")])],
                    2
                  )
                : _c("span", [
                    _vm._v(_vm._s(_vm.yingye.form.registeredCapital) + " 万元")
                  ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "经营范围：" } },
            [
              _vm.yingyeEdit1
                ? _c("el-input", {
                    model: {
                      value: _vm.businessLicenseForm.businessScope,
                      callback: function($$v) {
                        _vm.$set(_vm.businessLicenseForm, "businessScope", $$v)
                      },
                      expression: "businessLicenseForm.businessScope"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.businessScope))])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tit-box d2-p-10 d2-pl-20",
          attrs: { flex: "main:left cross:center" }
        },
        [
          _c("b", { staticClass: "d2-pr" }, [_vm._v("法人信息")]),
          _c(
            "span",
            {
              staticStyle: { color: "#02a7f0", cursor: "pointer" },
              on: {
                click: function($event) {
                  return _vm.clickEdit("2")
                }
              }
            },
            [_vm._v(_vm._s(_vm.yingye.edit2 ? "保存" : "修改"))]
          )
        ]
      ),
      _c(
        "el-form",
        {
          staticClass: "d2-p",
          staticStyle: { width: "50%" },
          attrs: {
            model: _vm.legalPersonForm,
            rules: _vm.yingye.rules,
            "label-position": "right",
            "label-width": "120px"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "法人身份证件：" } }, [
            _vm.yingyeEdit2
              ? _c(
                  "div",
                  { attrs: { flex: "main" } },
                  [
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            "on-success": function(res) {
                              return _vm.handleSuccess(
                                res,
                                "legalPersonIdCardFace",
                                _vm.legalPersonForm
                              )
                            },
                            "before-upload": _vm.beforeUpload
                          }
                        },
                        "el-upload",
                        _vm.uploadData,
                        false
                      ),
                      [
                        _vm.legalPersonForm.legalPersonIdCardFace
                          ? _c("el-image", {
                              staticClass: "avatar",
                              staticStyle: {
                                width: "240px",
                                height: "151px",
                                "margin-right": "10px"
                              },
                              attrs: {
                                fit: "cover",
                                src: _vm.legalPersonForm.legalPersonIdCardFace
                              }
                            })
                          : _c("div", [
                              _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              }),
                              _c(
                                "span",
                                { staticClass: "avatar-uploader-text" },
                                [_vm._v("上传人面像")]
                              )
                            ])
                      ],
                      1
                    ),
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            "on-success": function(res) {
                              return _vm.handleSuccess(
                                res,
                                "legalPersonIdCardEmblem",
                                _vm.legalPersonForm
                              )
                            },
                            "before-upload": _vm.beforeUpload
                          }
                        },
                        "el-upload",
                        _vm.uploadData,
                        false
                      ),
                      [
                        _vm.legalPersonForm.legalPersonIdCardEmblem
                          ? _c("el-image", {
                              staticClass: "avatar",
                              staticStyle: { width: "240px", height: "151px" },
                              attrs: {
                                fit: "cover",
                                src: _vm.legalPersonForm.legalPersonIdCardEmblem
                              }
                            })
                          : _c("div", [
                              _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              }),
                              _c(
                                "span",
                                { staticClass: "avatar-uploader-text" },
                                [_vm._v("上传国徽面")]
                              )
                            ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("el-image", {
                      staticClass: "el-image",
                      staticStyle: {
                        "margin-right": "10px",
                        width: "240px",
                        height: "151px"
                      },
                      attrs: {
                        src: _vm.yingye.form.legalPersonIdCardFace,
                        fit: "cover",
                        "preview-src-list": [
                          _vm.yingye.form.legalPersonIdCardFace
                        ]
                      }
                    }),
                    _c("el-image", {
                      staticClass: "el-image",
                      staticStyle: { width: "240px", height: "151px" },
                      attrs: {
                        src: _vm.yingye.form.legalPersonIdCardEmblem,
                        fit: "cover",
                        "preview-src-list": [
                          _vm.yingye.form.legalPersonIdCardEmblem
                        ]
                      }
                    })
                  ],
                  1
                )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "法人姓名：" } },
            [
              _vm.yingyeEdit2
                ? _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.legalPersonForm.legalPersonName,
                      callback: function($$v) {
                        _vm.$set(_vm.legalPersonForm, "legalPersonName", $$v)
                      },
                      expression: "legalPersonForm.legalPersonName"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.legalPersonName))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "法人证件号：" } },
            [
              _vm.yingyeEdit2
                ? _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.legalPersonForm.legalPersonIdCardNumber,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.legalPersonForm,
                          "legalPersonIdCardNumber",
                          $$v
                        )
                      },
                      expression: "legalPersonForm.legalPersonIdCardNumber"
                    }
                  })
                : _c("span", [
                    _vm._v(_vm._s(_vm.yingye.form.legalPersonIdCardNumber))
                  ])
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "法人证件有效期：" } }, [
            _vm.yingyeEdit2
              ? _c(
                  "div",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        type: "date",
                        placeholder: "请选择"
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.legalPersonForm.legalPersonIdCardTimeFrom,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.legalPersonForm,
                            "legalPersonIdCardTimeFrom",
                            $$v
                          )
                        },
                        expression: "legalPersonForm.legalPersonIdCardTimeFrom"
                      }
                    }),
                    _c("span", { staticClass: "d2-pl d2-pr" }, [_vm._v("至")]),
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        type: "date",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.legalPersonForm.legalPersonIdCardTimeTo,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.legalPersonForm,
                            "legalPersonIdCardTimeTo",
                            $$v
                          )
                        },
                        expression: "legalPersonForm.legalPersonIdCardTimeTo"
                      }
                    }),
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "d2-ml",
                        on: { change: _vm.handleChangeChecked },
                        model: {
                          value: _vm.legalPersonForm.legalPersonIdCardLongTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.legalPersonForm,
                              "legalPersonIdCardLongTime",
                              $$v
                            )
                          },
                          expression:
                            "legalPersonForm.legalPersonIdCardLongTime"
                        }
                      },
                      [_vm._v("长期有效")]
                    )
                  ],
                  1
                )
              : _c("div", [
                  !_vm.yingye.form.legalPersonIdCardLongTime
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.yingye.form.legalPersonIdCardTimeFrom) +
                            " 至 " +
                            _vm._s(_vm.yingye.form.legalPersonIdCardTimeTo)
                        )
                      ])
                    : _c("span", [_vm._v("长期有效")])
                ])
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tit-box d2-p-10 d2-pl-20",
          attrs: { flex: "main:left cross:center" }
        },
        [
          _c("b", { staticClass: "d2-pr" }, [_vm._v("税务信息")]),
          _c(
            "span",
            {
              staticStyle: { color: "#02a7f0", cursor: "pointer" },
              on: {
                click: function($event) {
                  return _vm.clickEdit("3")
                }
              }
            },
            [_vm._v(_vm._s(_vm.yingye.edit3 ? "保存" : "修改"))]
          )
        ]
      ),
      _c(
        "el-form",
        {
          staticClass: "d2-p",
          staticStyle: { width: "50%" },
          attrs: {
            model: _vm.taxpayerForm,
            rules: _vm.yingye.rules,
            "label-position": "right",
            "label-width": "120px"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "纳税人类型：" } }, [
            _vm.yingyeEdit3
              ? _c(
                  "div",
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.taxpayerForm.taxpayerType,
                          callback: function($$v) {
                            _vm.$set(_vm.taxpayerForm, "taxpayerType", $$v)
                          },
                          expression: "taxpayerForm.taxpayerType"
                        }
                      },
                      [_vm._v("一般纳税人")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 2 },
                        model: {
                          value: _vm.taxpayerForm.taxpayerType,
                          callback: function($$v) {
                            _vm.$set(_vm.taxpayerForm, "taxpayerType", $$v)
                          },
                          expression: "taxpayerForm.taxpayerType"
                        }
                      },
                      [_vm._v("小规模纳税人")]
                    )
                  ],
                  1
                )
              : _c("span", [
                  _vm._v(
                    _vm._s(
                      ["", "一般纳税人", "小规模纳税人"][
                        _vm.yingye.form.taxpayerType
                      ]
                    )
                  )
                ])
          ]),
          _vm.taxpayerForm.taxpayerType == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "一般纳税人资格证：" } },
                [
                  _vm.yingyeEdit3
                    ? _c(
                        "el-upload",
                        _vm._b(
                          {
                            attrs: {
                              "on-success": function(res) {
                                return _vm.handleSuccess(
                                  res,
                                  "taxpayerCertImage",
                                  _vm.taxpayerForm
                                )
                              },
                              "before-upload": _vm.beforeUpload
                            }
                          },
                          "el-upload",
                          _vm.uploadData,
                          false
                        ),
                        [
                          _vm.taxpayerForm.taxpayerCertImage
                            ? _c("el-image", {
                                staticClass: "avatar",
                                attrs: {
                                  fit: "cover",
                                  src: _vm.taxpayerForm.taxpayerCertImage
                                }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ],
                        1
                      )
                    : _c("el-image", {
                        staticClass: "el-image",
                        attrs: {
                          src: _vm.yingye.form.taxpayerCertImage,
                          fit: "cover",
                          "preview-src-list": [
                            _vm.yingye.form.taxpayerCertImage
                          ]
                        }
                      })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "合同编号：" } },
            [
              _vm.yingyeEdit3
                ? _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.taxpayerForm.contractNo,
                      callback: function($$v) {
                        _vm.$set(_vm.taxpayerForm, "contractNo", $$v)
                      },
                      expression: "taxpayerForm.contractNo"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.contractNo))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户行：" } },
            [
              _vm.yingyeEdit3
                ? _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.taxpayerForm.bankName,
                      callback: function($$v) {
                        _vm.$set(_vm.taxpayerForm, "bankName", $$v)
                      },
                      expression: "taxpayerForm.bankName"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.bankName))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账号：" } },
            [
              _vm.yingyeEdit3
                ? _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.taxpayerForm.bankAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.taxpayerForm, "bankAccount", $$v)
                      },
                      expression: "taxpayerForm.bankAccount"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.bankAccount))])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支行名称：" } },
            [
              _vm.yingyeEdit3
                ? _c("el-input", {
                    staticStyle: { width: "300px" },
                    model: {
                      value: _vm.taxpayerForm.bankBranchName,
                      callback: function($$v) {
                        _vm.$set(_vm.taxpayerForm, "bankBranchName", $$v)
                      },
                      expression: "taxpayerForm.bankBranchName"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.bankBranchName))])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "tit-box d2-p-10 d2-pl-20",
          attrs: { flex: "main:left cross:center" }
        },
        [
          _c("b", { staticClass: "d2-pr" }, [_vm._v("店铺设置")]),
          _c(
            "span",
            {
              staticStyle: { color: "#02a7f0", cursor: "pointer" },
              on: {
                click: function($event) {
                  return _vm.clickEdit("4")
                }
              }
            },
            [_vm._v(_vm._s(_vm.yingye.edit4 ? "保存" : "修改"))]
          )
        ]
      ),
      _c(
        "el-form",
        {
          staticClass: "d2-p",
          staticStyle: { width: "50%" },
          attrs: {
            model: _vm.storeForm,
            rules: _vm.yingye.rules,
            "label-position": "right",
            "label-width": "120px"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "店铺名称：" } }, [
            _c("span", [_vm._v(_vm._s(_vm.yingye.form.storeName))])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "店铺logo：" } },
            [
              _vm.yingyeEdit4
                ? _c(
                    "el-upload",
                    _vm._b(
                      {
                        attrs: {
                          "on-success": function(res) {
                            return _vm.handleSuccess(
                              res,
                              "storeLogo",
                              _vm.storeForm
                            )
                          },
                          "before-upload": _vm.beforeUpload
                        }
                      },
                      "el-upload",
                      _vm.uploadData,
                      false
                    ),
                    [
                      _vm.yingye.form.storeLogo
                        ? _c("el-image", {
                            staticClass: "avatar",
                            attrs: {
                              fit: "cover",
                              src: _vm.yingye.form.storeLogo
                            }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ],
                    1
                  )
                : _c("el-image", {
                    staticClass: "el-image",
                    attrs: {
                      src: _vm.yingye.form.storeLogo,
                      fit: "cover",
                      "preview-src-list": [_vm.yingye.form.storeLogo]
                    }
                  })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺简介：" } },
            [
              _vm.yingyeEdit4
                ? _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.storeForm.storeIntro,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "storeIntro", $$v)
                      },
                      expression: "storeForm.storeIntro"
                    }
                  })
                : _c("span", [_vm._v(_vm._s(_vm.yingye.form.storeIntro))])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }