import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
var route = [{
  path: '/payment',
  name: 'payment',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '保证金缴纳'
  }),
  component: _import('deposit/payment')
}, {
  path: '/paymentBook',
  name: 'paymentBook',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '诚信保证金协议'
  }),
  component: _import('deposit/paymentBook')
}, {
  path: '/examine',
  name: 'examine',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '等待工作人员确认'
  }),
  component: _import('deposit/examine')
}, {
  path: '/examineError',
  name: 'examineError',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '保证金缴纳审核未通过'
  }),
  component: _import('deposit/examine/examineError')
}];
export default route;