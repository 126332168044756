var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      attrs: { betterScroll: "" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              ),
              _vm.showTransferStatus(_vm.dataObj, 2)
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubim } },
                    [_vm._v("确认提交")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("d2-page-head", {
            attrs: {
              tip: _vm._f("ftValue2label")(
                _vm.dataObj.transferStatus,
                _vm.dict.walletStatus
              )
            }
          })
        ],
        1
      ),
      _c(
        "el-card",
        [
          _c(
            "el-descriptions",
            { attrs: { column: 2 } },
            [
              _c("el-descriptions-item", { attrs: { label: "提现编号" } }, [
                _vm._v(_vm._s(_vm.dataObj.transferNo))
              ]),
              _c("el-descriptions-item", { attrs: { label: "状态" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("ftValue2label")(
                      _vm.dataObj.transferStatus,
                      _vm.dict.walletStatus
                    )
                  )
                )
              ]),
              _vm.showTransferStatus(_vm.dataObj, 3)
                ? _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        span: 2,
                        label: "原因",
                        labelStyle: { color: "red" }
                      }
                    },
                    [_vm._v(_vm._s(_vm.dataObj.failureReason))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-descriptions",
            { staticClass: "d2-mt d2-mb", attrs: { column: 4, title: "单据" } },
            [
              _c("el-descriptions-item", { attrs: { label: "要炼应收合计" } }, [
                _vm._v("￥" + _vm._s(_vm.totalIncome.toFixed(2)))
              ]),
              _c("el-descriptions-item", { attrs: { label: "要炼应付合计" } }, [
                _vm._v("￥" + _vm._s(_vm.totalPay.toFixed(2)))
              ]),
              _c("el-descriptions-item", { attrs: { label: "要炼应结合计" } }, [
                _vm._v("￥" + _vm._s(_vm.totalOpen.toFixed(2)))
              ]),
              _c("el-descriptions-item", { attrs: { label: "店铺开票金额" } }, [
                _vm._v(" ￥" + _vm._s(_vm.totalOpen.toFixed(2)) + " ")
              ])
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.dataObj.billList,
                "header-cell-style": { background: "#F6F6F6" },
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "billNo", label: "单据编号", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "relationNo",
                  align: "center",
                  label: "订单编号"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsAmount", label: "货款", align: "center" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryAmount",
                  label: "配送费",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "commissionAmount",
                  label: "佣金",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "billDate", label: "单据日期", align: "center" }
              })
            ],
            1
          ),
          _c(
            "el-descriptions",
            {
              staticClass: "d2-mt d2-mb",
              attrs: {
                column: 1,
                title: "收款账户",
                labelStyle: { "padding-left": "35px" }
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "开户名称" } }, [
                _vm._v(_vm._s(_vm.dataObj.bankAccountName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "开户账号" } }, [
                _vm._v(_vm._s(_vm.dataObj.bankAccount))
              ]),
              _c("el-descriptions-item", { attrs: { label: "开户银行支行" } }, [
                _vm._v(_vm._s(_vm.dataObj.bankName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "联行号" } }, [
                _vm._v(" " + _vm._s(_vm.dataObj.bankNo) + " ")
              ])
            ],
            1
          ),
          _vm.showTransferStatus(_vm.dataObj, 2)
            ? [
                _c("b", [_vm._v("开票信息")]),
                _c(
                  "el-form",
                  {
                    ref: "openInfoData",
                    staticClass: "d2-mt",
                    staticStyle: { width: "500px" },
                    attrs: {
                      model: _vm.openInfoData,
                      rules: _vm.rules,
                      "label-width": "120px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "发票类型：", prop: "invoiceType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.openInfoData.invoiceType,
                              callback: function($$v) {
                                _vm.$set(_vm.openInfoData, "invoiceType", $$v)
                              },
                              expression: "openInfoData.invoiceType"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("电子普通发票")
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("增值税专用发票")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发票日期：", prop: "invoiceType" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.openInfoData.invoiceDate,
                            callback: function($$v) {
                              _vm.$set(_vm.openInfoData, "invoiceDate", $$v)
                            },
                            expression: "openInfoData.invoiceDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发票代码：", prop: "invoiceCode" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", clearable: "" },
                          model: {
                            value: _vm.openInfoData.invoiceCode,
                            callback: function($$v) {
                              _vm.$set(_vm.openInfoData, "invoiceCode", $$v)
                            },
                            expression: "openInfoData.invoiceCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "发票号码：", prop: "invoiceNumber" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", clearable: "" },
                          model: {
                            value: _vm.openInfoData.invoiceNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.openInfoData, "invoiceNumber", $$v)
                            },
                            expression: "openInfoData.invoiceNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "价税合计：", prop: "priceTaxSubtotal" }
                      },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "", clearable: "" },
                            model: {
                              value: _vm.openInfoData.priceTaxSubtotal,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.openInfoData,
                                  "priceTaxSubtotal",
                                  $$v
                                )
                              },
                              expression: "openInfoData.priceTaxSubtotal"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { slot: "append" }, slot: "append" },
                              [_vm._v("元")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "税率：", prop: "taxRate" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", clearable: "" },
                          on: { input: _vm.handleInput },
                          model: {
                            value: _vm.openInfoData.taxRate,
                            callback: function($$v) {
                              _vm.$set(_vm.openInfoData, "taxRate", $$v)
                            },
                            expression: "openInfoData.taxRate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "不含税金额：",
                          prop: "excludingTaxAmount"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", disabled: "" },
                          model: {
                            value: _vm.openInfoData.excludingTaxAmount,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.openInfoData,
                                "excludingTaxAmount",
                                $$v
                              )
                            },
                            expression: "openInfoData.excludingTaxAmount"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "上传发票：", prop: "invoiceImage" } },
                      [
                        _vm.openInfoData.invoiceImage
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.openInfoData.invoiceImage,
                                  download: "",
                                  target: "blank"
                                }
                              },
                              [_vm._v(_vm._s(_vm.openInfoData.invoiceImage))]
                            )
                          : _vm._e(),
                        _c(
                          "el-upload",
                          _vm._b(
                            {
                              attrs: {
                                accept: ".pdf",
                                "on-success": function(res) {
                                  return _vm.handleSuccess(res, "invoiceImage")
                                },
                                "before-upload": _vm.beforeUpload
                              }
                            },
                            "el-upload",
                            _vm.uploadData,
                            false
                          ),
                          [
                            _c("el-button", { attrs: { type: "primary" } }, [
                              _vm._v("点击上传")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip"
                              },
                              [_vm._v("请上传发票PDF发票文件")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "收票人信息：",
                          prop: "invoicePeopleInfo"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              [
                                _vm.dataObj.invoiceName,
                                _vm.dataObj.invoicePhone,
                                _vm.dataObj.invoiceAddress
                              ]
                                .map(function(v) {
                                  return v
                                })
                                .join("，")
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "物流公司：", prop: "expressCompany" }
                      },
                      [
                        _c("base-select", {
                          attrs: { options: _vm.expressList },
                          model: {
                            value: _vm.openInfoData.expressCompany,
                            callback: function($$v) {
                              _vm.$set(_vm.openInfoData, "expressCompany", $$v)
                            },
                            expression: "openInfoData.expressCompany"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "物流单号：", prop: "expressNo" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "", clearable: "" },
                          model: {
                            value: _vm.openInfoData.expressNo,
                            callback: function($$v) {
                              _vm.$set(_vm.openInfoData, "expressNo", $$v)
                            },
                            expression: "openInfoData.expressNo"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.showTransferStatus(_vm.dataObj, 4)
            ? [
                _c(
                  "el-descriptions",
                  {
                    staticClass: "margin-top",
                    attrs: {
                      title: "开票信息",
                      column: 1,
                      size: _vm.size,
                      labelStyle: { "padding-left": "35px" }
                    }
                  },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "发票类型：" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.dataObj.invoiceType == 1
                              ? "电子普通发票"
                              : _vm.dataObj.invoiceType == 2
                              ? "增值税专用发票"
                              : ""
                          )
                        )
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "发票日期：" } },
                      [_vm._v(_vm._s(_vm.dataObj.invoiceDate))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "发票代码：" } },
                      [_vm._v(_vm._s(_vm.dataObj.invoiceCode))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "发票号码：" } },
                      [_vm._v(_vm._s(_vm.dataObj.invoiceNumber))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "价税合计：" } },
                      [_vm._v(_vm._s(_vm.dataObj.priceTaxSubtotal))]
                    ),
                    _c("el-descriptions-item", { attrs: { label: "税率：" } }, [
                      _vm._v(_vm._s(_vm.dataObj.taxRate))
                    ]),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "不含税金额：" } },
                      [_vm._v(_vm._s(_vm.dataObj.excludingTaxAmount))]
                    ),
                    _c("el-descriptions-item", { attrs: { label: "发票：" } }, [
                      _vm.dataObj.invoiceImage
                        ? _c(
                            "a",
                            {
                              staticStyle: { color: "#409eff" },
                              on: {
                                click: function($event) {
                                  return _vm.toDownloadFile(
                                    _vm.dataObj.invoiceImage,
                                    _vm.dataObj.invoiceImage
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.dataObj.invoiceImage))]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "收票人信息：" } },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              [
                                _vm.dataObj.invoiceName,
                                _vm.dataObj.invoicePhone,
                                _vm.dataObj.invoiceAddress
                              ]
                                .map(function(v) {
                                  return v
                                })
                                .join(" ")
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "物流公司：" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("expressCompany")(_vm.dataObj.expressCompany)
                          )
                        )
                      ]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "物流单号：" } },
                      [_vm._v(_vm._s(_vm.dataObj.expressNo))]
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }