import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    money: {
      type: Number
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};