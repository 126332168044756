import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';

var userImg = require("@/assets/image/user.png");

export default {
  data: function data() {
    return {
      userImg: userImg
    };
  },
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  methods: _objectSpread(_objectSpread({}, mapActions({
    logout: 'd2admin/account/logout',
    closeAll: 'd2admin/page/closeAll'
  })), {}, {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },
    clickRoute: function clickRoute(name) {
      this.$router.push({
        name: name
      });
    }
  })
};