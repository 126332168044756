export default [{
  path: '/index',
  title: '首页',
  iconSvg: '首页'
}, {
  path: '/storeInfo',
  title: '店铺信息',
  iconSvg: '店铺信息'
}, {
  title: '商品管理',
  iconSvg: '商品管理',
  children: [{
    path: '/saleTrade',
    title: '在售商品管理'
  }, {
    path: '/editTrade',
    title: '编辑商品信息'
  }, {
    path: '/addTrade',
    title: '选择商品分类'
  }, {
    path: '/newAddTrade',
    title: '添加新商品'
  }, {
    path: '/comsTrade',
    title: '待售商品管理'
  }, {
    path: '/violation',
    title: '违规原因'
  }, {
    path: '/comment',
    title: '商品评价'
  }, {
    path: '/commentDetail',
    title: '评价详情'
  }, {
    path: '/commentModel',
    title: '回复模板'
  }, {
    path: '/tabTrade',
    title: '商品分类'
  }, {
    path: '/twoTabTrade',
    title: '二级分类'
  }, {
    path: '/editTabTrade',
    title: '添加/编辑分类'
  }, {
    path: '/saleAttrs',
    title: '销售属性'
  }, {
    path: '/saleSet',
    title: '管理属性值'
  }]
}, {
  title: '订单管理',
  iconSvg: '订单管理',
  children: [{
    path: '/orderSearch',
    title: '订单查询与跟踪'
  }, {
    path: '/orderDetail',
    title: '订单详情'
  }, {
    path: '/stay',
    title: '出库'
  }, {
    path: '/delayShip',
    title: '延迟发货记录'
  }, {
    path: '/outOrder',
    title: '出库单打印'
  }, {
    path: '/printOrder',
    title: '打印出库单'
  }]
}, {
  title: '配送管理',
  iconSvg: '配送管理',
  children: [{
    path: '/returnShop',
    title: '退货地址管理'
  }, {
    path: '/addReturnShop',
    title: '新增退货地址'
  }, {
    path: '/editReturnShop',
    title: '修改退货地址'
  }, {
    path: '/sendShop',
    title: '发货地址管理'
  }, {
    path: '/addSendShop',
    title: '新增发货地址'
  }, {
    path: '/editSendShopp',
    title: '修改发货地址'
  }, {
    path: '/transport',
    title: '运费模板'
  }, {
    path: '/addTransportModule',
    title: '添加模板'
  }, {
    path: '/distributionArea',
    title: '配送区域管理'
  }]
}, {
  title: '售后服务',
  iconSvg: '售后服务',
  children: [{
    path: '/afterSale',
    title: '退换货服务单'
  }, {
    path: '/returnGoods',
    title: '退货'
  }, {
    path: '/exchangeGoods',
    title: '换货'
  }, {
    path: '/sendNewGoods',
    title: '发出新货'
  }, {
    path: '/sendNewDetail',
    title: '换新发货详情'
  }, {
    path: '/cancelOrder',
    title: '取消订单'
  }, {
    path: '/cancelOrderDetail',
    title: '服务单详情'
  }]
}, {
  title: '结算管理',
  iconSvg: '结算管理',
  children: [{
    path: '/wallet',
    title: '钱包提现'
  }, {
    path: '/walletDetail',
    title: '提现详情'
  }, {
    path: '/walletProcess',
    title: '选择单据'
  }, {
    path: '/walletSubmit',
    title: '提现申请'
  }, {
    path: '/bill',
    title: '账单查询'
  }, {
    path: '/billDetail',
    title: '明细账单查询'
  }, {
    path: '/bond',
    title: '保证金'
  }, {
    path: '/bondWait',
    title: '等待工作人员确认'
  }, {
    path: '/bondSuccess',
    title: '保证金缴纳审核通过详情'
  }, {
    path: '/bondError',
    title: '保证金缴纳审核未通过'
  }, {
    path: '/bondMake',
    title: '保证金补足缴纳'
  }, {
    path: '/bondAgreement',
    title: '保证金协议'
  }, {
    path: '/bondAgree',
    title: '诚信保证金协议'
  }, {
    path: '/returnBond',
    title: '申请退保证金'
  }, {
    path: '/returnBondWait',
    title: '审核中退保详情'
  }, {
    path: '/returnBondError',
    title: '审核未通过退保详情'
  }, {
    path: '/returnBondSuccess',
    title: '审核通过退保详情'
  }]
}, {
  title: '数据统计',
  iconSvg: '数据统计',
  children: [{
    path: '/realTime',
    title: '实时概况'
  }, {
    path: '/transaction',
    title: '交易分析'
  }, {
    path: '/commodity',
    title: '商品明细'
  }, {
    path: '/service',
    title: '退换货服务分析'
  }, {
    path: '/evaluate',
    title: '评价分析'
  }, {
    path: '/news',
    title: '消息中心'
  }]
}, {
  title: '账号管理',
  iconSvg: '账号管理',
  children: [{
    path: '/role',
    title: '角色管理'
  }, {
    path: '/power',
    title: '权限设置'
  }, {
    path: '/staff',
    title: '员工管理'
  }, {
    path: '/addStaff',
    title: '添加员工'
  }, {
    path: '/editStaff',
    title: '编辑员工'
  }]
}, {
  title: '登录和忘记密码',
  icon: 'folder-o',
  hidden: true,
  children: [{
    path: '/login',
    title: '店铺登录'
  }, {
    path: '/forgetPwd',
    title: '找回密码'
  }, {
    path: '/editPwd',
    title: '修改密码'
  }, {
    path: '/editPhone',
    title: '修改手机号'
  }]
}, {
  title: '测试单元',
  icon: 'folder-o',
  hidden: true,
  children: [{
    path: '/test',
    title: '数据大屏'
  }, {
    path: '/test1',
    title: '应急大屏'
  }, {
    path: '/test2',
    title: '新闻大屏'
  }]
}, {
  title: '店铺入驻',
  icon: 'folder-o',
  children: [{
    path: '/account',
    title: '注册账号'
  }, {
    path: '/settled',
    title: '入驻申请'
  }, {
    path: '/resident',
    title: '入驻审核中'
  }, {
    path: '/resError',
    title: '入驻审核未通过'
  }, {
    path: '/resSucc',
    title: '入驻审核通过'
  }]
}, {
  title: '支付保证金',
  icon: 'folder-o',
  children: [{
    path: '/payment',
    title: '保证金缴纳'
  }, {
    path: '/paymentBook',
    title: '诚信保证金协议'
  }, {
    path: '/examine',
    title: '等待工作人员确认'
  }, {
    path: '/examineError',
    title: '保证金缴纳审核未通过'
  }]
}];