//
//
//
//
//
//
//
//
//
//
import TopHome from './components/top-home.vue';
import CenterHome from './components/center-home.vue';
import BottomHome from './components/bottom-home.vue';
export default {
  name: 'home',
  components: {
    TopHome: TopHome,
    BottomHome: BottomHome,
    CenterHome: CenterHome
  }
};