var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "服务单号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入服务单号" },
                model: {
                  value: _vm.form.refundNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "refundNo", $$v)
                  },
                  expression: "form.refundNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单号" },
                model: {
                  value: _vm.form.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户期望" } },
            [
              _c("base-select", {
                attrs: { options: _vm.dict.refundType },
                model: {
                  value: _vm.form.refundType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "refundType", $$v)
                  },
                  expression: "form.refundType"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请客户" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入申请客户" },
                model: {
                  value: _vm.form.consumerName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "consumerName", $$v)
                  },
                  expression: "form.consumerName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.applyTimeRange.start,
                  max: _vm.form.applyTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.auditTimeRange.start,
                  max: _vm.form.auditTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-table",
        { ref: "baseTable", attrs: { request: _vm.request } },
        [
          _c("el-table-column", {
            attrs: { prop: "refundNo", label: "服务单号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "订单编号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.handleGoRoute(row.orderId)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.orderNo))]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.copyText(row.orderNo)
                              }
                            }
                          },
                          [_vm._v("复制")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "consumerName", label: "客户姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "d2-mr-10" }, [
                          _vm._v(_vm._s(row.consumerName))
                        ]),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.isImFormIdSet(row.userId)
                              }
                            }
                          },
                          [_c("u", [_vm._v("联系")])]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "refundType",
              label: "客户期望及原因",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.refundType,
                            _vm.dict.refundType
                          )
                        )
                      )
                    ]),
                    _c("div", [_vm._v(_vm._s(row.refundReason))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "refundStatus", label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.refundType == 1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("ftValue2label")(
                                row.refundStatus,
                                _vm.dict.refundStatus
                              )
                            )
                          )
                        ])
                      : _vm._e(),
                    row.refundType == 2
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("ftValue2label")(
                                row.refundStatus,
                                _vm.dict.exchangeStatus
                              )
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "applyTime", label: "申请时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "auditTime", label: "商家审核时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute("returnGoods", {
                              refundId: row.refundId
                            })
                          }
                        }
                      },
                      [_vm._v("服务单详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }