var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", {
        attrs: {
          slot: "header",
          tip: _vm._f("ftValue2label")(
            _vm.detailsData.orderStatus,
            _vm.dict.cancelStatus
          )
        },
        slot: "header"
      }),
      _c(
        "el-descriptions",
        {
          staticClass: "d2-mb-15",
          attrs: {
            column: 2,
            border: "",
            labelStyle: { width: "170px", "text-align": "center" }
          }
        },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: "订单号" } },
            [
              _c(
                "span",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function($event) {
                      return _vm.goRoute("orderDetail", {
                        orderId: _vm.detailsData.orderId
                      })
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.detailsData.orderNo) + " ")]
              ),
              _c(
                "el-link",
                {
                  attrs: { type: "primary", underline: false },
                  on: {
                    click: function($event) {
                      return _vm.copyText(_vm.detailsData.orderNo)
                    }
                  }
                },
                [_vm._v("复制")]
              )
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: "客户" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.detailsData.nickname +
                      "(" +
                      _vm.detailsData.userNo +
                      ")"
                  ) +
                  " "
              ),
              _c(
                "el-link",
                {
                  attrs: { type: "primary", underline: false },
                  on: {
                    click: function($event) {
                      return _vm.isImFormIdSet(_vm.detailsData.userId)
                    }
                  }
                },
                [_vm._v("联系")]
              )
            ],
            1
          ),
          _c("el-descriptions-item", { attrs: { label: "订单当前状态" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("ftValue2label")(
                    _vm.detailsData.currentOrderStatus,
                    _vm.dict.orderStatus
                  )
                ) +
                " "
            )
          ]),
          _c(
            "el-descriptions-item",
            {
              attrs: { label: "订单取消时状态", contentStyle: _vm.contentStyle }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("ftValue2label")(
                      _vm.detailsData.orderStatus,
                      _vm.dict.orderStatus
                    )
                  ) +
                  " "
              )
            ]
          ),
          _c("el-descriptions-item", { attrs: { label: "服务单号" } }, [
            _vm._v(" " + _vm._s(_vm.detailsData.refundNo) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "申请日期" } }, [
            _vm._v(
              " " + _vm._s(_vm._f("formatDateTime")(_vm.detailsData.applyTime))
            )
          ]),
          _c("el-descriptions-item", { attrs: { label: "申请理由" } }, [
            _vm._v(" " + _vm._s(_vm.detailsData.refundReason) + " ")
          ]),
          _c(
            "el-descriptions-item",
            { attrs: { label: "退款金额", contentStyle: _vm.contentStyle } },
            [_vm._v(" " + _vm._s(_vm.detailsData.refundAmount) + " ")]
          ),
          _c("el-descriptions-item", { attrs: { label: "物流信息" } }, [
            _vm._v(" " + _vm._s(_vm.detailsData.backExpressCompany) + " ")
          ]),
          _c("el-descriptions-item", { attrs: { label: "" } })
        ],
        1
      ),
      _vm.detailsData.refundStatus !== 1
        ? _c(
            "el-descriptions",
            {
              staticClass: "d2-mb-15",
              attrs: {
                column: 1,
                border: "",
                labelStyle: { width: "170px", "text-align": "center" }
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "实际出库情况" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("ftValue2label")(
                        _vm.detailsData.actualDelivery,
                        _vm.dict.houseStatus
                      )
                    ) +
                    " "
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "审核意见" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("ftValue2label")(
                        _vm.detailsData.refundStatus,
                        _vm.radioGroupStatus
                      )
                    ) +
                    " "
                )
              ]),
              _vm.detailsData.refundStatus == 3
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "商家驳回原因" } },
                    [_vm._v(" " + _vm._s(_vm.detailsData.rejectReason) + " ")]
                  )
                : _vm._e(),
              _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                _vm._v(" " + _vm._s(_vm.detailsData.auditDescription) + " ")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.detailsData.refundStatus == 1
        ? _c(
            "el-descriptions",
            {
              staticClass: "d2-mb-15",
              attrs: {
                column: 1,
                border: "",
                labelStyle: { width: "170px", "text-align": "center" }
              }
            },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "实际出库情况" } },
                [
                  _c("base-select", {
                    attrs: { options: _vm.dict.houseStatus },
                    model: {
                      value: _vm.form.actualDeliveryStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "actualDeliveryStatus", $$v)
                      },
                      expression: "form.actualDeliveryStatus"
                    }
                  }),
                  _c(
                    "span",
                    { staticClass: "d2-ml", staticStyle: { color: "red" } },
                    [_vm._v("注：审核通过需填写实际出库情况")]
                  )
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "审核意见" } },
                [
                  _c("base-radio-group", {
                    attrs: { options: _vm.radioGroupStatus },
                    model: {
                      value: _vm.form.refundStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "refundStatus", $$v)
                      },
                      expression: "form.refundStatus"
                    }
                  })
                ],
                1
              ),
              _vm.form.refundStatus == 3
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "商家驳回原因" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.form.rejectDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rejectDescription", $$v)
                          },
                          expression: "form.rejectDescription"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-descriptions-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.auditDescription,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "auditDescription", $$v)
                      },
                      expression: "form.auditDescription"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.detailsData.refundStatus == 1
        ? _c(
            "div",
            { staticClass: "d2-mt", attrs: { flex: "main:center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onsubmit } },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }