var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("请选择要调整属性的类目")
        ]),
        _c(
          "div",
          { staticClass: "body" },
          [_c("selectComp", { on: { change: _vm.selectCompChange } })],
          1
        )
      ]),
      _c("div", { staticClass: "card d2-mt-10" }, [
        _c("div", { staticClass: "header" }, [_vm._v("销售属性列表")]),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  border: "",
                  stripe: "",
                  "header-cell-style": { background: "#F6F6F6" }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "attributeName", label: "属性名字" }
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "200px", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleNav("saleSet", {
                                    sellAttributeId: row.sellAttributeId
                                  })
                                }
                              }
                            },
                            [_vm._v("管理属性值")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c("d2-pagination", {
              attrs: { total: _vm.total },
              on: { pagination: _vm.pagination }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }