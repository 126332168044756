var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("span", { staticClass: "d2-mr-10" }, [_vm._v("服务单状态：")]),
            _c(
              "span",
              { staticStyle: { "font-size": "18px", color: "#70b685" } },
              [_vm._v("待收货")]
            ),
            _c("span", { staticStyle: { color: "#d9001b" } }, [
              _vm._v("剩余10小时12分")
            ]),
            _c("el-button", { attrs: { type: "primary" } }, [
              _vm._v("确认收货")
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-descriptions",
              {
                staticClass: "d2-mb-15",
                attrs: {
                  column: 1,
                  border: "",
                  labelStyle: { "text-align": "right", width: "200px" }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "body-tit" }, [
                          _vm._v("服务单信息")
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              [
                _c("el-descriptions-item", { attrs: { label: "服务单号" } }, [
                  _vm._v(" 313860326 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "退货商品" } }, [
                  _vm._v(" XXXXXXXX动感单车 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "客户期望" } }, [
                  _vm._v(" 换货 白色/M码 SKU：1245635 ")
                ]),
                _c(
                  "el-descriptions-item",
                  { attrs: { label: "换货收货地址" } },
                  [_vm._v(" XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX ")]
                ),
                _c("el-descriptions-item", { attrs: { label: "申请原因" } }, [
                  _vm._v(" 七天无理由 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "退款描述" } }, [
                  _vm._v(" 描述文字XXXXXX ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "客户图片" } }, [
                  _vm._v(" img ")
                ])
              ],
              1
            ),
            _c(
              "el-descriptions",
              {
                staticClass: "d2-mb-15",
                attrs: {
                  column: 1,
                  border: "",
                  labelStyle: { "text-align": "right", width: "200px" }
                }
              },
              [
                _c("el-descriptions-item", { attrs: { label: "订单编号" } }, [
                  _vm._v(" 202107196398345 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "申请时间" } }, [
                  _vm._v(" 2021-07-19 14:48:38 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "发票信息" } }, [
                  _vm._v(" 广州XXXXXXXXX有限公司 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "下单账号" } }, [
                  _vm._v(" 15918565852 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
                  _vm._v(" 质量问题 ")
                ]),
                _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                  _vm._v(" 描述文字XXXXXX")
                ]),
                _c("el-descriptions-item", { attrs: { label: "收货地址" } }, [
                  _vm._v(" XXXXXXXXXXXXXXXXXXXXX ")
                ])
              ],
              1
            ),
            _c(
              "el-descriptions",
              {
                staticClass: "d2-mb-15",
                attrs: {
                  column: 1,
                  border: "",
                  labelStyle: { display: "none" }
                },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "body-tit",
                            staticStyle: { "font-weight": "800" }
                          },
                          [_vm._v("协商记录")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              },
              _vm._l(5, function(l) {
                return _c("el-descriptions-item", { key: l }, [
                  _c("div", { staticClass: "item-list" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("i", { staticClass: "el-icon-user" })
                    ]),
                    _c("div", { staticClass: "right" }, [
                      _c("div", { staticClass: "top" }, [
                        _c("b", [_vm._v("黄小玉(工号1002)")]),
                        _c("span", [_vm._v("(2021-11-19 00:22:58)")])
                      ]),
                      _c("div", { staticClass: "center" }, [
                        _c("span", [_vm._v("审核通过")])
                      ]),
                      _c("div", { staticClass: "bottom" }, [
                        _c("span", [_vm._v("审核意见：请勿影响二次销售")]),
                        _c("span", [
                          _vm._v("申请原因:由【质量问题】改为【七天无理由】")
                        ])
                      ])
                    ])
                  ])
                ])
              }),
              1
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }