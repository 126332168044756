var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "上传文件",
        "append-to-body": true,
        visible: _vm.dialogVisible,
        width: "50%"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.token || _vm.uploadOpts.token
        ? [
            _c(
              "el-upload",
              {
                staticClass: "upload-demo",
                attrs: {
                  data: _vm.uploadOpts,
                  action: _vm.upAction,
                  "before-upload": _vm.beforeUpload,
                  "on-success": _vm.handleSuccess,
                  "file-list": _vm.sourceFileList
                }
              },
              [
                _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                  _vm._v("点击上传")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "el-upload__tip",
                    attrs: { slot: "tip" },
                    slot: "tip"
                  },
                  [_vm._v("只能上传" + _vm._s(_vm.type) + "文件")]
                )
              ],
              1
            ),
            _vm.lastFile && _vm.type === "image"
              ? _c("el-image", {
                  staticStyle: { height: "200px", width: "200px" },
                  attrs: { fit: "contain", src: _vm.lastFile }
                })
              : _vm._e(),
            _vm.lastFile && _vm.type === "video"
              ? _c("video", {
                  staticClass: "form-video",
                  attrs: { controls: "", src: _vm.lastFile }
                })
              : _vm._e(),
            _vm.lastFile && _vm.type === "audio"
              ? _c("video", {
                  staticClass: "form-audio",
                  attrs: { controls: "", src: _vm.lastFile }
                })
              : _vm._e(),
            _c(
              "el-form",
              {
                staticStyle: { "margin-top": "10px" },
                attrs: { "label-width": "150px" }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "链接（可手动配置）" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.lastFile,
                        callback: function($$v) {
                          _vm.lastFile = $$v
                        },
                        expression: "lastFile"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _vm._t("footer", [
                  _c("el-button", { on: { click: _vm.onChancel } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v("确 定")]
                  )
                ])
              ],
              2
            )
          ]
        : _c("div", { staticClass: "tips" }, [_vm._v(" 正在初始化数据 ")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }