var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-login" }, [
    _c(
      "div",
      { staticClass: "page-card" },
      [
        _c("p", { staticClass: "page-card-tit" }, [
          _vm._v(_vm._s(_vm.VUE_APP_TITLE))
        ]),
        _c(
          "el-form",
          {
            ref: "loginForm",
            attrs: {
              "label-position": "top",
              rules: _vm.rules,
              model: _vm.formLogin
            }
          },
          [
            _c("el-form-item", { attrs: { prop: "account" } }, [
              _c(
                "div",
                { staticClass: "login-input" },
                [
                  _c(
                    "i",
                    [
                      _c("el-image", {
                        staticStyle: { width: "26px", height: "26px" },
                        attrs: { src: _vm.countImg }
                      })
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入账号" },
                    model: {
                      value: _vm.formLogin.account,
                      callback: function($$v) {
                        _vm.$set(_vm.formLogin, "account", $$v)
                      },
                      expression: "formLogin.account"
                    }
                  })
                ],
                1
              )
            ]),
            _c("el-form-item", { attrs: { prop: "password" } }, [
              _c(
                "div",
                { staticClass: "login-input" },
                [
                  _c(
                    "i",
                    [
                      _c("el-image", {
                        staticStyle: { width: "26px", height: "26px" },
                        attrs: { src: _vm.pwdImg }
                      })
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: { type: "password", placeholder: "请输入密码" },
                    model: {
                      value: _vm.formLogin.password,
                      callback: function($$v) {
                        _vm.$set(_vm.formLogin, "password", $$v)
                      },
                      expression: "formLogin.password"
                    }
                  })
                ],
                1
              )
            ]),
            _c("el-form-item", { attrs: { prop: "code" } }, [
              _c(
                "div",
                { staticClass: "login-input" },
                [
                  _c(
                    "i",
                    [
                      _c("el-image", {
                        staticStyle: { width: "26px", height: "26px" },
                        attrs: { src: _vm.codeImg }
                      })
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入验证码" },
                    model: {
                      value: _vm.formLogin.code,
                      callback: function($$v) {
                        _vm.$set(_vm.formLogin, "code", $$v)
                      },
                      expression: "formLogin.code"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "login-code" },
                    [
                      _c("d2-code", {
                        ref: "code",
                        on: { getIdentifyCode: _vm.getCode }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "login-text-btn" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { color: "#3061f1" },
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.goRoute("account")
                      }
                    }
                  },
                  [_c("span", [_vm._v("没有账号? 店铺入驻申请 >")])]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { color: "#666666" },
                    attrs: { type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.goRoute("forgetPwd")
                      }
                    }
                  },
                  [_c("span", [_vm._v("忘记密码? ")])]
                )
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "login-button",
                attrs: { type: "primary" },
                on: { click: _vm.submit }
              },
              [_c("span", [_vm._v("登录")])]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "login-footer" },
      [
        _vm._v(" Copyright "),
        _c("d2-icon", { attrs: { name: "copyright" } }),
        _vm._v("2022 要炼健康科技(深圳)有限公司 yaolianfit.com 版权所有 ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }