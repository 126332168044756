import "D:\\mzkj\\\u8981\u70BC\\yl-pc-business\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\mzkj\\\u8981\u70BC\\yl-pc-business\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\mzkj\\\u8981\u70BC\\yl-pc-business\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\mzkj\\\u8981\u70BC\\yl-pc-business\\node_modules\\core-js\\modules\\es.promise.finally.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App';
import draggable from 'vuedraggable'; // 核心插件

import d2Admin from '@/plugin/d2admin'; // store

import store from '@/store/index';
import globalInstall from './components/utils/globalInstall'; // 注册全局组件

import PageContent from '@/components/global/base-layout/PageContent.vue';
import BaseDialog from '@/components/global/base-dialog';
import BaseForm from '@/components/global/base-form';
import BaseSelect from '@/components/global/base-select';
import BaseRadioGroup from '@/components/global/base-radio-group';
import BaseTable from '@/components/global/base-table';
import BaseGallery from '@/components/global/base-gallery';
import RangeMonth from '@/components/global/range-month';
import RangeDate from '@/components/global/range-date';
import RangeDatetime from '@/components/global/range-datetime';
import Rangetime from '@/components/global/range-time';
import RangeInput from '@/components/global/range-input';
import BaseUpload from '@/components/global/base-upload';
import CascaderSelect from '@/components/global/cascader-select';
import CaseCheckboxGroup from '@/components/global/base-checkbox-group';
import ImageUpload from '@/components/global/image-upload';
import FormGrid from '@/components/global/form-grid';
import TableTitleBar from '@/components/global/base-table/TableTitleBar';
globalInstall(PageContent, 'page-content');
globalInstall(BaseDialog, 'base-dialog');
globalInstall(BaseForm, 'base-form');
globalInstall(BaseSelect, 'base-select');
globalInstall(BaseRadioGroup, 'base-radio-group');
globalInstall(BaseTable, 'base-table');
globalInstall(BaseGallery, 'base-gallery');
globalInstall(RangeMonth, 'range-month');
globalInstall(RangeDate, 'range-date');
globalInstall(RangeDatetime, 'range-datetime');
globalInstall(Rangetime, 'range-time');
globalInstall(RangeInput, 'range-input');
globalInstall(BaseUpload, 'base-upload');
globalInstall(CascaderSelect, 'cascader-select');
globalInstall(CaseCheckboxGroup, 'base-checkbox-group');
globalInstall(FormGrid, 'form-grid');
globalInstall(ImageUpload, 'image-upload');
globalInstall(draggable, 'draggable');
globalInstall(TableTitleBar, 'TableTitleBar'); // 菜单和路由设置

import router from './router';
import { menuHeader, menuAside } from '@/menu';
import { frameInRoutes } from '@/router/routes'; // 过滤器

import '@/filters';
import minxi from '@/minxi';
Vue.mixin(minxi); // 核心插件

Vue.use(d2Admin);
window.app = new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes); // 设置顶栏菜单

    this.$store.commit('d2admin/menu/headerSet', menuHeader); // 设置侧边栏菜单
    // this.$store.commit('d2admin/menu/asideSet', menuAside)
    // 初始化菜单搜索功能

    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');