export default [{
  path: '/index',
  title: '首页',
  iconSvg: '首页',
  authorityValue: 'store-router-page'
}, {
  path: '/storeInfo',
  title: '店铺信息',
  iconSvg: '店铺信息',
  authorityValue: 'store-store-management'
}, {
  title: '商品管理',
  iconSvg: '商品管理',
  authorityValue: 'store-goods-management',
  children: [{
    path: '/saleTrade',
    title: '在售商品管理',
    authorityValue: 'store-onshelf-manage'
  }, {
    path: '/addTrade',
    title: '添加新商品',
    authorityValue: 'store-goods-add'
  }, {
    path: '/comsTrade',
    title: '待售商品管理',
    authorityValue: 'store-offshelf-manage'
  }, {
    path: '/comment',
    title: '商品评价管理',
    authorityValue: 'store-evaluate-manage'
  }, {
    path: '/tabTrade',
    title: '店内商品分类',
    authorityValue: 'store-store-subcate'
  }, {
    path: '/saleAttrs',
    title: '商品属性',
    authorityValue: 'store-goods-sellatt'
  }]
}, {
  title: '订单管理',
  iconSvg: '订单管理',
  authorityValue: 'store-order-management',
  children: [{
    path: '/orderSearch',
    title: '订单查询与跟踪',
    authorityValue: 'store-order-list'
  }, {
    path: '/delayShip',
    title: '延迟发货记录',
    authorityValue: 'store-overdue-manage'
  }, {
    path: '/outOrder',
    title: '出库单打印',
    authorityValue: 'store-delivery-records'
  }]
}, {
  title: '配送管理',
  iconSvg: '配送管理',
  authorityValue: 'store-delivery-management',
  children: [{
    path: '/returnShop',
    title: '退货地址管理',
    authorityValue: 'store-delivery-refund'
  }, {
    path: '/sendShop',
    title: '发货地址管理',
    authorityValue: 'store-delivery-address'
  }, {
    path: '/transport',
    title: '设置运费模板',
    authorityValue: 'store-delivery-temp'
  }, {
    path: '/distributionArea',
    title: '配送区域管理',
    authorityValue: 'store-delivery-area'
  }]
}, {
  title: '售后服务',
  iconSvg: '售后服务',
  authorityValue: 'store-post-sale_management',
  children: [{
    path: '/afterSale',
    title: '退换货服务单',
    authorityValue: 'store-refund-manage'
  }, {
    path: '/cancelOrder',
    title: '取消订单管理',
    authorityValue: 'store-cancel-manage'
  }]
}, {
  title: '结算管理',
  iconSvg: '结算管理',
  authorityValue: 'store-financial-management',
  children: [{
    path: '/wallet',
    title: '钱包提现',
    authorityValue: 'store-transfers-manage'
  }, {
    path: '/bill',
    title: '账单查询',
    authorityValue: 'store-store-bill-list'
  }, {
    path: '/billDetail',
    title: '明细账单查询',
    authorityValue: 'store-store-bill-logs'
  }, {
    path: '/bond',
    title: '保证金',
    authorityValue: 'store-store-bond-info'
  }]
}, {
  title: '数据统计',
  iconSvg: '数据统计',
  authorityValue: 'store-statistics-management',
  children: [{
    path: '/realTime',
    title: '实时概况',
    authorityValue: 'store-statistics-management'
  }, {
    path: '/transaction',
    title: '交易分析',
    authorityValue: 'store-statistics-management'
  }, {
    path: '/commodity',
    title: '商品明细',
    authorityValue: 'store-statistics-management'
  }, {
    path: '/service',
    title: '退换货服务分析',
    authorityValue: 'store-statistics-management'
  }, {
    path: '/evaluate',
    title: '评价分析',
    authorityValue: 'store-statistics-management'
  }]
}, {
  title: '账号管理',
  iconSvg: '账号管理',
  authorityValue: 'store-account-management',
  children: [{
    path: '/role',
    title: '角色管理',
    authorityValue: 'store-account-management'
  }, {
    path: '/staff',
    title: '员工管理',
    authorityValue: 'store-account-management'
  }]
}];