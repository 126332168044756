var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("template", { slot: "header" }, [_c("d2-page-head")], 1),
      _c(
        "div",
        { staticClass: "card d2-p" },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 1 } },
            [
              _c("el-descriptions-item", { attrs: { label: "查找商品" } }, [
                _c("div", { staticClass: "descriptions-item" }, [
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("label", [_vm._v("商品名称")]),
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: { input: _vm.handleInput },
                        model: {
                          value: _vm.form.goodsName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "goodsName", $$v)
                          },
                          expression: "form.goodsName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("label", [_vm._v("店铺类目")]),
                      _c("el-cascader", {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          options: _vm.storeTypeOptions,
                          props: {
                            expandTrigger: "hover",
                            value: "goodsTypeId",
                            label: "typeName"
                          },
                          clearable: ""
                        },
                        on: { change: _vm.handleStoreChange },
                        model: {
                          value: _vm.form.storeGoodsTypeFirst,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "storeGoodsTypeFirst", $$v)
                          },
                          expression: "form.storeGoodsTypeFirst"
                        }
                      }),
                      _vm.storeTypeOptions2.length > 0
                        ? _c("el-cascader", {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              options: _vm.storeTypeOptions2,
                              clearable: "",
                              props: {
                                expandTrigger: "hover",
                                value: "goodsTypeId",
                                label: "typeName"
                              }
                            },
                            on: { change: _vm.handleStoreChange2 },
                            model: {
                              value: _vm.form.storeGoodsTypeId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "storeGoodsTypeId", $$v)
                              },
                              expression: "form.storeGoodsTypeId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item" },
                    [
                      _c("range-date", {
                        attrs: { clearable: true },
                        on: { change: _vm.rangeDateChange }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "关注浏览" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleClick },
                      model: {
                        value: _vm.checkList,
                        callback: function($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "访客数" } }),
                      _c("el-checkbox", { attrs: { label: "浏览量" } }),
                      _c("el-checkbox", { attrs: { label: "关注数" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "下单与成交" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { min: 0, max: 5 },
                      on: { change: _vm.handleClick2 },
                      model: {
                        value: _vm.checkList2,
                        callback: function($$v) {
                          _vm.checkList2 = $$v
                        },
                        expression: "checkList2"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "加购商品件数" } }),
                      _c("el-checkbox", { attrs: { label: "加购客户数" } }),
                      _c("el-checkbox", { attrs: { label: "下单客户数" } }),
                      _c("el-checkbox", { attrs: { label: "下单单量" } }),
                      _c("el-checkbox", { attrs: { label: "下单转化率" } }),
                      _c("el-checkbox", { attrs: { label: "下单成交转化率" } }),
                      _c("el-checkbox", { attrs: { label: "下单金额" } }),
                      _c("el-checkbox", { attrs: { label: "成交客户数" } }),
                      _c("el-checkbox", { attrs: { label: "成交单量" } }),
                      _c("el-checkbox", { attrs: { label: "成交金额" } }),
                      _c("el-checkbox", { attrs: { label: "成交转化率" } }),
                      _c("el-checkbox", { attrs: { label: "客单价" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "d2-mt",
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": { background: "#F6F6F6" },
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren"
                },
                "row-key": "id"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "details",
                  label: "商品信息",
                  align: "center",
                  width: "400px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              [
                                row.goodsPrimaryImage
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "75px",
                                        height: "75px"
                                      },
                                      attrs: {
                                        src: row.goodsPrimaryImage,
                                        "preview-src-list": [
                                          row.goodsPrimaryImage
                                        ],
                                        fit: "cover"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: { "margin-right": "10px" },
                                on: {
                                  click: function($event) {
                                    return _vm.goRoute("")
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.goodsName))]
                            ),
                            _c("span", [_vm._v(_vm._s(row.goodsNo))])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._l(_vm.tableColumns, function(item, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    align: "center",
                    sortable: ""
                  }
                })
              })
            ],
            2
          ),
          _c("d2-pagination", {
            attrs: { total: _vm.total },
            on: { pagination: _vm.pagination }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }