import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var initForm = {
  refundNo: '',
  orderNo: '',
  refundType: '',
  consumerName: '',
  applyTimeRange: {
    start: '',
    end: ''
  },
  auditTimeRange: {
    start: '',
    end: ''
  },
  refundStatus: ''
};
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
export default {
  data: function data() {
    return {
      initForm: initForm,
      form: cloneDeep(initForm),
      request: {
        api: 'refunds',
        params: cloneDeep(initForm)
      }
    };
  },
  computed: _objectSpread({}, mapState('d2admin', {
    menu: function menu(state) {
      return state.permission.routeKey;
    }
  })),
  methods: {
    isShow: function isShow(auto) {
      return this.isBtnGroud(this.menu, auto);
    },
    handleSearch: function handleSearch(values) {
      this.$set(this.request, 'params', values);
      this.$refs.baseTable.loadData();
    },
    handleGoRoute: function handleGoRoute(id) {
      if (this.isShow('store-order-management')) {
        this.goRoute('orderDetail', {
          orderId: id
        });
      } else {
        this.$message.error('您没有权限访问');
      }
    }
  }
};