var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d2-mb" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "box" },
      [
        _vm.btnList.length > 0
          ? _c(
              "el-row",
              { attrs: { gutter: 20, align: "middle", justify: "between" } },
              _vm._l(_vm.btnList, function(l, i) {
                return _c("el-col", { key: i, attrs: { span: 4 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "item card",
                      on: {
                        click: function($event) {
                          return _vm.goRouteInit(l.key)
                        }
                      }
                    },
                    [
                      _c("el-image", {
                        staticClass: "img",
                        attrs: { src: l.icon, fit: "cover" }
                      }),
                      _c("div", { staticClass: "label" }, [
                        _vm._v(_vm._s(l.label))
                      ])
                    ],
                    1
                  )
                ])
              }),
              1
            )
          : _c(
              "el-row",
              {
                staticStyle: { height: "100%" },
                attrs: { type: "flex", align: "middle", justify: "center" }
              },
              [_vm._v("暂无访问权限")]
            )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBox" }, [
      _c("div", { staticClass: "block" }),
      _c("div", { staticClass: "text" }, [_vm._v("常用功能")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }