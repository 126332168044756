//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    form: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      tableData: []
    };
  },
  created: function created() {
    console.log(this.form);
  }
};