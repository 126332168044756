var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        position: "absolute",
        height: "0",
        left: "-5000000px",
        top: "-500000px"
      }
    },
    [
      _c("div", { attrs: { id: _vm.qr.id } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/image/yl_logo.png") }
        }),
        _c("h2", { staticClass: "title" }, [_vm._v("要炼购物中心")]),
        _c("div", { staticClass: "price-box" }, [
          _c("div", { staticClass: "price-title" }, [_vm._v("应付金额:")]),
          _c("div", { staticClass: "price-count" }, [
            _vm._v("¥" + _vm._s(_vm.printData.price))
          ])
        ]),
        _c("div", { staticClass: "list-box" }, [
          _c("h3", [_vm._v("客户信息")]),
          _c("ul", { staticClass: "list" }, [
            _c("li", [_vm._v("订单编号:" + _vm._s(_vm.printData.id))]),
            _c("li", [_vm._v("出库时间:" + _vm._s(_vm.printData.date))]),
            _c("li", { staticStyle: { width: "100%" } }, [
              _vm._v("客户姓名:" + _vm._s(_vm.printData.name))
            ]),
            _c("li", { staticStyle: { width: "100%" } }, [
              _vm._v("联系电话:" + _vm._s(_vm.printData.phone))
            ]),
            _c("li", { staticStyle: { width: "100%" } }, [
              _vm._v("客户地址:" + _vm._s(_vm.printData.address))
            ]),
            _c("li", { staticStyle: { width: "100%" } }, [
              _vm._v(
                _vm._s(
                  _vm._f("ftValue2label")(
                    _vm.printData.isPois,
                    _vm.dict.invoiceTitleType
                  )
                )
              )
            ]),
            _c("li", { staticStyle: { width: "100%" } }, [
              _vm._v("备注:" + _vm._s(_vm.printData.remoke))
            ]),
            _c("li", { staticStyle: { width: "100%" } }, [
              _vm._v("商家备注:" + _vm._s(_vm.printData.store_remoke))
            ])
          ])
        ]),
        _c("div", { staticClass: "table-box" }, [
          _c("h3", { staticStyle: { "padding-left": "20px" } }, [
            _vm._v("商品信息")
          ]),
          _c(
            "table",
            { attrs: { cellspacing: "0", cellpadding: "0" } },
            [
              _vm._m(0),
              _vm._l(_vm.printData.list, function(item) {
                return _c("tr", { key: item.id }, [
                  _c("td", [_vm._v(_vm._s(item.id))]),
                  _c("td", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(_vm._s(item.name))
                  ]),
                  _c("td", { staticStyle: { width: "25%" } }, [
                    _vm._v(_vm._s(item.attr))
                  ]),
                  _c("td", { staticStyle: { width: "5%" } }, [
                    _vm._v(_vm._s(item.count))
                  ]),
                  _c("td", { staticStyle: { width: "8%" } }, [
                    _vm._v(_vm._s(item.price))
                  ]),
                  _c("td", { staticStyle: { width: "8%" } }, [
                    _vm._v(_vm._s(item.all))
                  ])
                ])
              })
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("商品编号")]),
      _c("th", { staticStyle: { width: "30%" } }, [_vm._v("商品名称")]),
      _c("th", { staticStyle: { width: "25%" } }, [_vm._v("货号属性")]),
      _c("th", { staticStyle: { width: "5%" } }, [_vm._v("数量")]),
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("价格")]),
      _c("th", { staticStyle: { width: "8%" } }, [_vm._v("小计")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }