var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d2-pb" },
    [
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单编号" },
                model: {
                  value: _vm.form.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称" },
                model: {
                  value: _vm.form.goodsName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "goodsName", $$v)
                  },
                  expression: "form.goodsName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单时间" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.orderTimeRange.start,
                  max: _vm.form.orderTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.orderTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户电话" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入客户电话" },
                model: {
                  value: _vm.form.phoneNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phoneNumber", $$v)
                  },
                  expression: "form.phoneNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单账号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入下单账号" },
                model: {
                  value: _vm.form.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nickname", $$v)
                  },
                  expression: "form.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单来源" } },
            [
              _c("base-select", {
                attrs: { options: _vm.dict.orderPlatform },
                model: {
                  value: _vm.form.placePlatform,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "placePlatform", $$v)
                  },
                  expression: "form.placePlatform"
                }
              })
            ],
            1
          ),
          _vm.isShowAuto("store-order-export")
            ? _c(
                "el-button",
                {
                  attrs: { slot: "extra", type: "default" },
                  on: { click: _vm.download },
                  slot: "extra"
                },
                [_vm._v("导出订单")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          attrs: { request: _vm.request },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "订单编号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderTime", label: "下单时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "payTime", label: "付款时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "下单账号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "storeRemark", label: "商家备注", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderStatus", label: "订单状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.orderStatus,
                            _vm.dict.orderStatus
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute("orderDetail", {
                              orderId: row.orderId
                            })
                          }
                        }
                      },
                      [_vm._v("订单详情")]
                    ),
                    row.orderStatus == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.clickLayer(
                                  {
                                    type: "updateDeliveryAmount",
                                    title: "修改运费"
                                  },
                                  row
                                )
                              }
                            }
                          },
                          [_vm._v("修改运费")]
                        )
                      : _vm._e(),
                    row.orderStatus <= 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.clickLayer(
                                  {
                                    type: "updateConsignee",
                                    title: "修改收货地址"
                                  },
                                  row
                                )
                              }
                            }
                          },
                          [_vm._v("修改收货地址")]
                        )
                      : _vm._e(),
                    row.orderStatus == 2
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.goRoute("stay", {
                                    orderId: row.orderId
                                  })
                                }
                              }
                            },
                            [_vm._v("订单出库")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.clickLayer(
                                    {
                                      type: "overdueDelivery",
                                      title: "延迟出库提醒"
                                    },
                                    row
                                  )
                                }
                              }
                            },
                            [_vm._v("延迟出库提醒")]
                          )
                        ]
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "d2-layer",
        { attrs: { layer: _vm.layer }, on: { confirm: _vm.confirm } },
        [
          _vm.active.type == "updateDeliveryAmount"
            ? _c(
                "div",
                [
                  _c(
                    "el-descriptions",
                    { attrs: { direction: "vertical", column: 2, border: "" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "原运费(元)" } },
                        [_vm._v(_vm._s(_vm.active.deliveryAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "运费(元)" } },
                        [
                          _c("el-input-number", {
                            attrs: { controls: false },
                            model: {
                              value: _vm.layer.form.deliveryAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.layer.form, "deliveryAmount", $$v)
                              },
                              expression: "layer.form.deliveryAmount"
                            }
                          }),
                          _c("span", [_vm._v("直接输入优惠后的金额")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.active.type == "updateConsignee"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "tip" }, [
                    _c("i", { staticClass: "el-icon-info" }),
                    _c("span", [
                      _vm._v(
                        "请核对订单后，再修改收货地址（暂且仅支持修改一次）"
                      )
                    ])
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "layerForm",
                      attrs: { model: _vm.layer.form, "label-width": "120px" }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "订单号" } }, [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "#ff873a",
                              "font-weight": "900"
                            }
                          },
                          [_vm._v(_vm._s(_vm.active.orderNo))]
                        )
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户姓名", prop: "consigneeName" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.layer.form.consigneeInfo.consigneeName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.layer.form.consigneeInfo,
                                  "consigneeName",
                                  $$v
                                )
                              },
                              expression:
                                "layer.form.consigneeInfo.consigneeName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所在地区" } },
                        [
                          _c("d2-city", {
                            attrs: {
                              disabled: true,
                              selfData: [
                                _vm.layer.form.consigneeInfo.consigneeProvince,
                                _vm.layer.form.consigneeInfo.consigneeCity,
                                _vm.layer.form.consigneeInfo.consigneeDistrict
                              ]
                            },
                            on: { change: _vm.cityChange }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "详细地址", prop: "consigneeDetails" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value:
                                _vm.layer.form.consigneeInfo.consigneeDetails,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.layer.form.consigneeInfo,
                                  "consigneeDetails",
                                  $$v
                                )
                              },
                              expression:
                                "layer.form.consigneeInfo.consigneeDetails"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号", prop: "consigneePhone" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "11" },
                            model: {
                              value:
                                _vm.layer.form.consigneeInfo.consigneePhone,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.layer.form.consigneeInfo,
                                  "consigneePhone",
                                  $$v
                                )
                              },
                              expression:
                                "layer.form.consigneeInfo.consigneePhone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "固定电话", prop: "consigneeTel" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.layer.form.consigneeInfo.consigneeTel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.layer.form.consigneeInfo,
                                  "consigneeTel",
                                  $$v
                                )
                              },
                              expression:
                                "layer.form.consigneeInfo.consigneeTel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.active.type == "overdueDelivery"
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "layerForm",
                      attrs: { model: _vm.layer.form, rules: _vm.layer.rules }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "提示:" } }, [
                        _c("div", { attrs: { flex: "main:justify" } }, [
                          _c("span", [
                            _vm._v(
                              " 1.延迟出库提醒确定后，系统将推送消息提醒用户。"
                            ),
                            _c("br"),
                            _vm._v(
                              " 2.延迟出库后，请在承诺的出库时间内进行出库。"
                            ),
                            _c("br")
                          ])
                        ])
                      ]),
                      _c("el-form-item", { attrs: { label: "订单号" } }, [
                        _c("p", [_vm._v(_vm._s(_vm.active.orderNo))])
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "出库时间", prop: "toTime" } },
                        [
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v("在")]),
                              _c("el-date-picker", {
                                staticClass: "d2-ml-10 d2-mr-10",
                                attrs: {
                                  type: "datetime",
                                  placeholder: "选择日期时间",
                                  "value-format": "yyyy-MM-dd HH:mm:ss"
                                },
                                model: {
                                  value: _vm.layer.form.toTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.layer.form, "toTime", $$v)
                                  },
                                  expression: "layer.form.toTime"
                                }
                              }),
                              _c("span", [_vm._v("前出库")])
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "延迟出库原因", prop: "reason" } },
                        [
                          _c(
                            "div",
                            { attrs: { flex: "main" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.layer.form.reason,
                                    callback: function($$v) {
                                      _vm.$set(_vm.layer.form, "reason", $$v)
                                    },
                                    expression: "layer.form.reason"
                                  }
                                },
                                _vm._l(_vm.dict.overdueReason, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.label
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "证明图片", prop: "imageUrl" } },
                        [
                          _c(
                            "el-upload",
                            _vm._b(
                              {
                                staticStyle: { display: "inline-block" },
                                attrs: {
                                  multiple: "",
                                  "on-success": function(res) {
                                    return _vm.handleSuccess(res, "imageUrl")
                                  },
                                  "before-upload": _vm.beforeUpload
                                }
                              },
                              "el-upload",
                              _vm.uploadData,
                              false
                            ),
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap"
                                  }
                                },
                                _vm._l(_vm.layer.form.imageUrl, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: { "margin-right": "10px" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handlClick(index)
                                        }
                                      }
                                    },
                                    [
                                      item
                                        ? _c("el-image", {
                                            staticStyle: { width: "100px" },
                                            attrs: {
                                              src: item,
                                              fit: "fill",
                                              lazy: true
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary" }
                                },
                                [_vm._v("点击上传")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tip", attrs: { flex: "main:justify" } },
                    [
                      _c("span", { staticStyle: { width: "50px" } }, [
                        _vm._v("提示:")
                      ]),
                      _c("span", [
                        _vm._v(" 1、单张大小不超过 2M，最多上传5张。"),
                        _c("br"),
                        _vm._v(" 2、支持 jpg，png 格式。"),
                        _c("br"),
                        _vm._v(" 3、图片质量要清晰，不能虚化。。"),
                        _c("br"),
                        _vm._v(
                          " 4、图片内容尽可能明确证明报备原因，例如上传聊天记录，国家型公告等。"
                        ),
                        _c("br")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }