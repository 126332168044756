import { render, staticRenderFns } from "./page.vue?vue&type=template&id=089e7faa&scoped=true&"
import script from "./page.vue?vue&type=script&lang=js&"
export * from "./page.vue?vue&type=script&lang=js&"
import style0 from "./page.vue?vue&type=style&index=0&id=089e7faa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089e7faa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\mzkj\\要炼\\yl-pc-business\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('089e7faa')) {
      api.createRecord('089e7faa', component.options)
    } else {
      api.reload('089e7faa', component.options)
    }
    module.hot.accept("./page.vue?vue&type=template&id=089e7faa&scoped=true&", function () {
      api.rerender('089e7faa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/login/page.vue"
export default component.exports