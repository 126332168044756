var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "el-card",
        { attrs: { shadow: "never", "body-style": { padding: "20px" } } },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAdd } },
                [_vm._v("添加角色")]
              )
            ],
            1
          ),
          _c(
            "base-table",
            { ref: "baseTable", attrs: { request: _vm.request } },
            [
              _c("el-table-column", {
                attrs: { label: "角色名称", prop: "positionName" }
              }),
              _c("el-table-column", {
                attrs: { label: "角色描述", prop: "positionId" }
              }),
              _c("el-table-column", {
                attrs: { label: "添加时间", prop: "createTime" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作", prop: "xxx" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.goRoute("power", {
                                  positionId: row.positionId
                                })
                              }
                            }
                          },
                          [_vm._v("权限设置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.onEdit(row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("span", { staticClass: "d2-pl-10" }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.clickDel({
                                  positionId: row.positionId
                                })
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }