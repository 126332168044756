var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { staticClass: "d2-mr", attrs: { size: "small" } },
    [
      _c(
        "div",
        { staticClass: "btn-text can-hover userBox" },
        [
          _c("el-image", {
            staticStyle: { width: "37px", height: "37px" },
            attrs: { src: _vm.userImg }
          }),
          _c("span", { staticClass: "btn-text can-hover" }, [
            _vm._v(_vm._s(_vm.info.name ? "你好 " + _vm.info.name : "未登录"))
          ])
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.clickRoute("editPhone")
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "power-off" }
              }),
              _vm._v(" 修改手机号 ")
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.clickRoute("editPwd")
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "power-off" }
              }),
              _vm._v(" 修改密码 ")
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.logOff($event)
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "power-off" }
              }),
              _vm._v(" 退出登录 ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }