var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "el-card",
          {
            attrs: {
              shadow: "never",
              "body-style": { padding: "20px", height: "200px" }
            }
          },
          [
            _c(
              "el-descriptions",
              { attrs: { title: "包裹", column: 1 } },
              [
                _c("el-descriptions-item", { attrs: { label: "送货方式" } }, [
                  _vm._v("普通快递")
                ]),
                _c("el-descriptions-item", { attrs: { label: "承运单号" } }, [
                  _vm._v("JDVC11589041237")
                ]),
                _c("el-descriptions-item", { attrs: { label: "承运人" } }, [
                  _vm._v("张三")
                ]),
                _c("el-descriptions-item", { attrs: { label: "承运人电话" } }, [
                  _vm._v("15918574585")
                ])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-card",
          {
            attrs: {
              shadow: "never",
              "body-style": { padding: "20px", height: "200px" }
            }
          },
          [
            _c(
              "el-timeline",
              { staticClass: "d2-pl-5" },
              _vm._l(_vm.activities, function(activity, index) {
                return _c(
                  "el-timeline-item",
                  {
                    key: index,
                    attrs: {
                      "hide-timestamp": "",
                      type: activity.type,
                      timestamp: activity.timestamp,
                      placement: "top",
                      color: activity.color
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "dot",
                          fn: function() {
                            return [
                              _c("div", {
                                staticClass: "dot",
                                class: { "is-new": !index }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "timeline-item",
                        style: { color: !index ? "#fa5454" : "" }
                      },
                      [
                        _c("span", { staticClass: "d2-mb-5" }, [
                          _vm._v(_vm._s(activity.timestamp))
                        ]),
                        _c("span", [_vm._v(_vm._s(activity.content))])
                      ]
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("span", [_vm._v("等待确认收货")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }