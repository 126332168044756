var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-class" }, [
    _c("h2", { staticStyle: { "text-align": "center" } }, [
      _vm._v(" 要炼诚信保证金")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "为促进要炼平台商家更好地服务消费者，商家入驻时须根据平台规定缴纳保证金，保证金缴纳标准为：" +
          _vm._s(_vm.money) +
          "元。本规则适用于要炼开放平台所有商家。"
      )
    ]),
    _vm._m(0),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "1.1保证金指商家以“一店铺一保证金“原则向要炼缴纳的用以保证其商品和服务质量的资金，要炼可以依照双方签署的协议或平台规则进行处置。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "1.2保证金可用余额是指保证金总额减已赔付金额，再减已标识的暂扣金额后的金额。"
      )
    ]),
    _vm._m(1),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "2.1商家应根据要炼开放平台要求缴纳保证金，作为商家侵权、违约、违规、未履行服务承诺时用于对消费者和其他权利人进行赔偿的资金。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("2.2对于商家所缴存的保证金，要炼不需向商家支付任何利息。")
    ]),
    _vm._m(2),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "3.1要炼有权根据商家的业务变化及实际赔付情况等以书面方式通知商家调整保证金金额。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "3.2如果商家曾因违规被要炼清退，若再次申请加入，要炼有权提高商家的保证金金额。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "3.3如果商家店铺受到行政机关调查处罚，要炼有权提高商家的保证金金额。"
      )
    ]),
    _vm._m(3),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "4.1若经要炼审核认定商家有下列情形之一的，要炼有权根据实际情况从商家已缴付的保证金中扣除有关的款项赔付消费者或要炼或相关权利人："
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v("4.1.1未履行或延迟履行任何服务承诺；")
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v("4.1.2出售假货或出售质量不合格商品；")
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v("4.1.3虚假或夸大宣传；")
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v("4.1.4侵犯知识产权、财产权、人身权利等权利；")
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v("4.1.5标错价无法发货；")
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v(
        "4.1.6对于消费者针对商家服务承诺的每一有效投诉，要炼将按货款金额的300%在保证金中做出标识（每单不少于500元）予以暂扣，有效投诉判断，以要炼确认为准；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v(
        "4.1.7其他违反要炼平台规则、与要炼签订的协议或相关的服务承诺的情形。要炼若提取商家的保证金进行任何赔付，将以电子邮件或短信等形式通知商家，并说明赔付原因及赔付金额。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("4.2保证金不足的情形处理：")
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v(
        "4.2.1当商家的保证金不足以赔付消费者及其他权利人时，商家应自行支付额外的赔付金额；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v(
        "4.2.2如商家的保证金不足以支付任何赔付金额，要炼有权中止或终止服务，包括但不限于采取冻结商家应结货款、将商家从平台清退、追究商家的违约责任等；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v(
        "4.2.3在商家的保证金不足时，要炼没有义务使用自有资金向消费者或其他权利人支付赔偿金、补偿金或其它任何款项；"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "4em" } }, [
      _vm._v(
        "4.3如商家违反有关的服务承诺和/或要炼平台规则和/或商家与要炼签署的其他协议，给要炼造成任何损失（包括但不限于诉讼赔偿、诉讼费用、律师费用等）的，要炼有权从保证金中扣除相当于要炼损失的金额。"
      )
    ]),
    _vm._m(4),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "5.1如因商家业务变化需补缴或保证金可用余额低于保证金总额的50%，商家应在出现该情况后5个自然日内及时补缴足额的保证金使可用余额达至最低保证金金额。要炼可向商家发出续费通知，如商家在要炼开放平台上有未结货款，要炼有权从该等款项中扣除以补足保证金。如商家逾期未能补足保证金，则要炼开放平台有权终止服务。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "5.2当商家申请退出（或被清退）要炼开放平台时，需先按照本协议规定补足保证金至最低保证金额度，如未按时补足，则要炼有权自任意未结货款中扣除予以补足，补足后才可发起后续流程。"
      )
    ]),
    _vm._m(5),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "6.1若商家申请退出（或被清退）要炼开放平台，须在所有订单终止、所有索赔完结且已完成交易的所有商品质保期均届满后，商家可向要炼发起退还保证金的申请。自终止合作之日起满3个月，若商家符合审批条件（如申请的材料齐全、信息完整无误、签字盖章完整、无未结算完的结算单等），要炼将扣除依据协议及相关规则等应扣除的部分后，将保证金余额退还至商家已验证支付保证金的公司账户中。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "6.2因出现本规则4.1中所述的行为或商家与要炼签署平台协议中约定的被要炼清退的商家店铺，保证金在清退时不予退还。"
      )
    ]),
    _vm._m(6),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "7.1保证金的缴存、赔付、退回等过程中所发生的银行收费等第三方费用，由商家承担，要炼可从保证金余额中扣除。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "7.2若商家信息变更，需要根据变更情况的不同（如单纯账号变更、公司更名、公司注销等），按照要炼的要求准备应提供的材料文件，并提前30个自然日以书面形式通知要炼，否则因商家信息的变更而造成的支付失败、延迟、错误，损失由商家自行承担。"
      )
    ]),
    _vm._m(7),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "8.1要炼开放平台商家的行为，发生在本管理规则生效之日以前的，适用当时的规则。发生在本管理规则生效之日以后的，适用本规则。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v("8.2要炼可根据平台运营情况随时调整本管理规则并向商家公示。")
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "8.3商家应遵守国家法律、行政法规、部门规章等规范性文件。对任何涉嫌违反国家法律、行政法规、部门规章等规范性文件的行为，本规则已有规定的，适用于本规则。本规则尚无规定的，要炼有权酌情处理。但要炼对商家的处理不免除其应承担的法律责任。商家在要炼的任何行为，应同时遵守与要炼及其关联公司签订的各项协议。"
      )
    ]),
    _c("p", { staticStyle: { "text-indent": "2em" } }, [
      _vm._v(
        "8.4本规则自发布之日起至2022年4月1日为试运行期，于2022年5月31日起生效，本规则与其他平台规则不一致的，以本规则为准。"
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第一章 定义")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第二章 保证金缴存")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第三章 保证金的调整")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第四章 保证金赔付")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第五章 保证金补缴")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第六章 保证金退回")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第七章 其他")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("strong", [_vm._v("第八章 附则")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }