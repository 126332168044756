import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  meta: {
    title: '诚信保证金协议',
    auth: true,
    authorityValue: "store-store-bond-info"
  },
  name: 'bondAgree',
  props: {
    money: {
      type: Number
    }
  }
};