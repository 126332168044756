var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mainBox" }, [
    _c(
      "div",
      { staticClass: "panel-item" },
      [
        _c("span", [_vm._v("选择1级分类")]),
        _c("el-cascader-panel", {
          ref: "panel1",
          attrs: { options: _vm.provinceData, props: _vm.props },
          on: { change: _vm.changeProvince },
          model: {
            value: _vm.address[0],
            callback: function($$v) {
              _vm.$set(_vm.address, 0, $$v)
            },
            expression: "address[0]"
          }
        })
      ],
      1
    ),
    _vm.level > 1
      ? _c(
          "div",
          { staticClass: "panel-item" },
          [
            _c("span", [_vm._v("选择2级分类")]),
            _c("el-cascader-panel", {
              ref: "panel2",
              attrs: { options: _vm.cityData, props: _vm.props },
              on: { change: _vm.changeCity },
              model: {
                value: _vm.address[1],
                callback: function($$v) {
                  _vm.$set(_vm.address, 1, $$v)
                },
                expression: "address[1]"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.level > 2
      ? _c(
          "div",
          { staticClass: "panel-item" },
          [
            _c("span", [_vm._v("选择3级分类")]),
            _c("el-cascader-panel", {
              ref: "panel3",
              attrs: { options: _vm.areaData, props: _vm.props },
              on: { change: _vm.changeArea },
              model: {
                value: _vm.address[2],
                callback: function($$v) {
                  _vm.$set(_vm.address, 2, $$v)
                },
                expression: "address[2]"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }