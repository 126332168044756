import _objectSpread from "D:/mzkj/\u8981\u70BC/yl-pc-business/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _mergeJSXProps from "@vue/babel-helper-vue-jsx-merge-props";
export default {
  name: 'LemonMessageTextdir',
  inheritAttrs: false,
  inject: ['IMUI'],
  render: function render() {
    var h = arguments[0];
    return h("lemon-message-basic", _mergeJSXProps([{}, {
      "props": _objectSpread({}, this.$attrs)
    }, {
      "scopedSlots": {
        content: function content(props) {
          //返回HTML结构
          return h("div", [h("span", [props.content]), h("span", {
            "class": 'floatClass',
            "style": props.flow ? 'right: -999999em' : 'left: -3em'
          }, [props.isPeerRead ? '已读' : '未读'])]);
        }
      }
    }]));
  }
};