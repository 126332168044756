//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var imgUrl = require('@/assets/image/img-time.png');

export default {
  data: function data() {
    return {
      subTitle: '已提交入驻申请，等待平台审核',
      title: '入驻审核未通过',
      imgUrl: imgUrl
    };
  },
  mounted: function mounted() {},
  methods: {}
};