var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-class" }, [
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("要炼企业用户注册协议")
    ]),
    _c("p", [
      _vm._v(
        "感谢您选择注册并使用要炼，在使用“要炼”软件及相关服务前，请您认真阅读本协议，并确认承诺同意遵守本协议之全部约定。本协议由您与要炼健康科技（深圳）有限公司（包括其关联机构，以下合称“本公司”）于您点击同意本协议之时生效。"
      )
    ]),
    _c("h4", [_vm._v("一、协议条款的确认及接受")]),
    _c("p", [
      _vm._v("1.1 要炼（包括网址为 "),
      _c(
        "span",
        { staticStyle: { color: "#409eFF" }, on: { click: _vm.toOpen } },
        [_vm._v("yaolianfit.com")]
      ),
      _vm._v(
        " 的网站，以及“要炼APP”）由要炼健康科技（深圳）有限公司（包括其关联机构，以下合称“本公司”）运营并享有完全的所有权及知识产权等权益，要炼提供的服务将完全按照其发布的条款和操作规则严格执行。"
      )
    ]),
    _c("p", [
      _vm._v(
        "1.2 您确认同意本协议（协议文本包括《要炼用户信息隐私协议》、《要炼PLUS会员卡协议》等协议及要炼已公示或将来公示的各项规则及提示，所有前述协议、规则及提示乃不可分割的整体，具有同等法律效力，共同构成用户使用”要炼”软件及相关服务的整体协议，以下合称“本协议”）所有条款并完成注册程序时，本协议在您与本公司间成立并发生法律效力，同时您成为要炼正式用户。"
      )
    ]),
    _c("h4", [_vm._v("二、帐号注册及使用规则")]),
    _c("p", [
      _vm._v(
        "2.1 您注册成功后，要炼将给予您一个用户帐号及相应的密码，该用户帐号和密码由您负责保管；您应当对以其用户帐号进行的所有活动和事件负法律责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.1 您注册成功后，要炼将给予您一个用户帐号及相应的密码，该用户帐号和密码由您负责保管；您应当对以其用户帐号进行的所有活动和事件负法律责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.3 您理解且确认，您在要炼注册帐号的所有权及有关权益均归本公司所有，您完成注册手续后仅享有该帐号的使用权。您的帐号仅限于您本人使用，未经本公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。如果本公司发现或者有合理理由认为使用者并非帐号初始注册人，公司有权在未通知您的情况下，暂停或终止向该注册帐号提供服务，并有权永久禁用该帐号或注销该帐号，而无需向注册该帐号的用户承担法律责任。"
      )
    ]),
    _vm._m(0),
    _c("p", [
      _vm._v(
        "2.5 为提高用户内容曝光量及发布效率，您同意将您在要炼帐号中所发布的全部内容均授权本公司以您的帐号自动同步发布至要炼所有平台，您的帐号之头像、昵称等公开信息可能会一并同步。用户在要炼发布、修改、删除内容的操作，均会同步到要炼所有平台中。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.6 您承诺不得以任何方式利用要炼直接或间接从事违反中国法律、以及社会公德的行为，要炼有权对违反上述承诺的内容予以删除。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.7 您不得利用本网站制作、上传、复制、发布、传播或者转载如下内容：（1）反对宪法所确定的基本原则的；（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；（3）损害国家荣誉和利益的；（4）煽动民族仇恨、民族歧视，破坏民族团结的；（5）破坏国家宗教政策，宣扬邪教和封建迷信的；（6）散布谣言，扰乱社会秩序，破坏社会稳定的；（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；（8）侮辱或者诽谤他人，侵害他人合法权益的；（9）侵害未成年人合法权益或者损害未成年人身心健康的；（10）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；（11）含有法律、行政法规禁止的其他内容的信息。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.8 要炼鼓励用户充分利用要炼平台自由地发布和共享自己的信息，用户对于其创作并在要炼上发布的合法内容（包括但不限于文字、图片、视频、音频等）依法享有著作权。您不应通过要炼发布他人受知识产权法律等保护的内容，除非获得他人的合法授权。就您发布的内容，若第三方向要炼投诉并提交初步证据，经要炼判断投诉属实的，要炼将会删除这些内容。如因您发布的内容给要炼带来损失的，您应当负责赔偿。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.9 要炼有权对您使用要炼的情况进行审查和监督，如您在使用要炼时违反任何本协议规定或要炼颁布的其他相关规则，要炼或其授权的人有权要 求您改正或直接采取一切必要的措施（包括但不限于更改或删除您的内容、暂停或终止您使用要炼的权利）以减轻您的不当行为造成的影响。"
      )
    ]),
    _c("p", [
      _vm._v(
        "2.10 如您需要终止使用要炼服务，您可以申请注销您的帐号。注销成功后，帐号记录、功能等将无法恢复或提供，但您仍应对您在注销帐号前且使用要炼服务期间的行为承担相应责任。"
      )
    ]),
    _c("h4", [_vm._v("三、服务内容")]),
    _c("p", [
      _vm._v(
        "3.1 本公司可能为不同的终端设备及使用需求开发不同的应用程序软件版本，您应当根据实际设备及需求状况获取、下载、安装合适的版本。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.2 本网站的服务具体内容根据实际情况提供，要炼保留变更、中断或终止部分或全部服务的权利。要炼不承担因业务调整给您造成的损失。除非本协议另有其它明示规定，增加或强化目前本网站的任何新功能，包括所推出的新产品，均受到本协议之规范。您了解并同意，本网站服务仅依其当前所呈现的状况提供，对于任何用户通讯或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，要炼均不承担任何责任。如您不接受服务调整，请停止使用本服务；否则，您的继续使用行为将被视为其对调整服务的完全同意且承诺遵守。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.3 要炼在提供服务时，可能会对部分服务的用户收取一定的费用。在此情况下，要炼会在相关页面上做明确的提示。如您拒绝支付该等费用，则不能使用相关的服务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.4 您理解，要炼仅提供相关的服务，除此之外与相关服务有关的设备（如电脑、调制解调器及其他与接入互联网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费）均应由您自行负担。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.5 要炼提供的服务内容可能包括：文字、软件、图片、视频等。所有这些内容受著作权、商标和其它财产所有权法律的保护。您只有在获得要炼或其他相关权利人的授权之后才能使用这些内容，不能擅自复制、再造这些内容、或创造与内容有关的派生产品。"
      )
    ]),
    _c("h4", [_vm._v("3.6 要炼会员服务")]),
    _c("p", [
      _vm._v(
        "3.6.1 要炼会员，是指在要炼具有良好消费记录的个人/商业注册用户根据要炼公布的收费标准支付相应的费用后获取的特殊资格。以下称“会员”。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.6.2 会员服务具体内容请参考《要炼PLUS会员卡协议》。基于业务的发展，要炼有权调整服务标准，调整后的服务标准自公布之日起生效。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.6.3 会员知悉并确认，当开通会员服务后，若您中途取消服务或终止资格的，要炼将不退还您已支付的会员服务费用。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.6.4 使用会员服务过程中，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应当承担因此而导致的一切后果，要炼有权向您的监护人追偿。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.6.5 您知悉并同意，要炼有权通邮件、短信等方式，向您发送会员相关活动的信息；"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.6.6 您确认会员服务仅限您本人使用，同时，您保证，您将合理使用会员服务，不会利用会员服务非法获利，不以任何形式转让您所享有的会员服务，不以任何形式将您所享有的会员服务借给他人使用，如要炼有合理理由怀疑您存在不当使用会员服务时，要炼有权取消您的会员资格且不退还您支付的会员服务费用，因此产生的相关责任及损失均由您自行承担，给要炼造成损失的，要炼保留向您追偿的权利。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.6.7 要炼保留在法律法规允许的范围内自行决定是否接受您的会员服务申请、调整会员服务内容、取消会员资格等权利。"
      )
    ]),
    _c("h4", [_vm._v("3.7 商品/服务的购买与评价")]),
    _c("p", [
      _vm._v(
        "3.7.1 您可以通过要炼商城购买要炼或者第三方商家为您提供的商品/服务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.7.2 您在要炼下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.7.3 您理解并同意：要炼上展示的商品/服务和价格等信息为向您发出的要约。您下单时须填写您希望购买的商品/服务数量、价款及支付方式、收货人、联系方式、收货地址等内容；付款及订单提交成功前，您有权修改所填信息。付款及订单提交成功后，将视您与销售商之间就您购买的商品/服务成立了合同关系，销售商会按照您在订单中订购的商品/服务进行发货。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.7.4 尽管销售商做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，本软件无法避免您提交的订单信息中的商品出现缺货情况；如您下单所购买的商品发生缺货，您有权取消订单以解除电子合同，销售商在取得您同意的前提下可以取消订单以解除电子合同，若您已经付款，则为您办理退款。"
      )
    ]),
    _c("p", [
      _vm._v(
        "3.7.5 您有权在要炼提供的评价系统中对与其达成交易的商品/服务进行评价。您的评价内容应当客观真实，不应包含任何污言秽语、色情低俗、恶意贬低及法律法规与本协议列明的其他禁止性信息；您不应以不正当方式帮助商家提升销量、信用或利用评价权利对相关商家进行恐吓、敲诈勒索等违法行为。要炼可按照本协议及相关法律法规的规定对您实施上述行为所产生的评价信息进行删除或屏蔽。"
      )
    ]),
    _c("h4", [_vm._v("四、知识产权")]),
    _c("p", [
      _vm._v(
        "4.1 本公司在“要炼”软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、商标、标识、音频、视频、图表、版面设计、电子文档等）的知识产权属于本公司所有。同时本公司提供服务所依托的软件的著作权、专利权、商标及其他知识产权均归本公司所有。未经本公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载“要炼”软件及相关服务中的内容）。"
      )
    ]),
    _c("p", [
      _vm._v(
        "4.2 请您在任何情况下都不要私自使用公司的包括但不限于“要炼”、“要炼APP”和“yaolianfit.com”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经公司事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由您承担全部法律责任。"
      )
    ]),
    _c("h4", [_vm._v("五、用户授权及隐私保护")]),
    _c("p", [
      _vm._v(
        "5.1 除非有相反证明，您知悉、理解并同意，为使您的作品得到更好的分享及推广，提高其传播价值及影响力，您通过要炼上传、发布或传输的内容（包括但不限文字，图像，音频，视频、直播内容等各种形式的内容及其中包括的音乐、声音、台词、视觉设计、对话等所有组成部分），授予本公司及其关联方一项全球范围内、免费、非独家、可再许可（通过多层次）的权利（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权及制作衍生品、表演和展示的权利等），上述权利的使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等使用。您在此确认并同意，“要炼”软件及相关服务、公司和/或公司品牌有关的任何宣传、推广、广告、营销和/或研究中使用和以其他方式开发内容（全部或部分）的权利和许可。为避免疑义，您理解并同意，上述授予的权利包括使用、复制和展示您拥有或被许可使用并植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记（如有）以及任何其他品牌、营销或推广资产、物料、素材等的权利和许可。基于部分功能的特性，您通过要炼发布的内容（包括但不限于内容中包含的声音、音频或对话等）可供其他用户使用“要炼”软件创作及发布相关内容时使用。"
      )
    ]),
    _c("p", [
      _vm._v(
        "5.2 您确认并同意授权本公司以公司名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，本公司有权对维权事宜做出决策并独立实施。"
      )
    ]),
    _c("p", [
      _vm._v(
        "5.3 保护用户隐私（请参见《要炼用户信息隐私协议》）是要炼的一项基本政策，要炼保证不对外公开或向第三方提供您的注册资料及您在使用本网站服务时存储在要炼的非公开内容，但下列情况除外：（1）事先获得您的明确授权；（2）根据有关的法律法规要求；（3）按照相关政府主管部门的要求；（4）为维护社会公众的利益；（5）维护要炼的合法权益。"
      )
    ]),
    _c("p", [
      _vm._v(
        "5.4 在获得您授权的情况下，要炼可能会与第三方合作向您提供相关的服务，在此情况下，如该第三方同意承担与要炼同等的保护用户隐私的责任，则要炼可将您的注册资料等提供给该第三方。"
      )
    ]),
    _c("h4", [_vm._v("六、免责声明")]),
    _c("p", [
      _vm._v(
        "6.1 您将照片、个人信息等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识此类风险的存在。您明确同意其使用“要炼”软件及相关服务所存在的风险（包括但不限于受到第三方侵权或对第三方造成侵权）完全由您自己承担；因您使用“要炼”软件及相关服务而产生的一切后果也由您自己承担，要炼对此不承担任何责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.2 要炼不担保本网站服务一定能满足您的要求，也不担保本网站服务不会中断，对本网站服务的及时性、安全性、准确性、真实性、完整性也都不作担保。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.3 对于因不可抗力或要炼不能控制的原因造成的本网站服务中断或其它缺陷，要炼不承担任何责任，但要炼将尽力减少因此而给您造成的损失和影响。"
      )
    ]),
    _c("p", [
      _vm._v(
        "6.4 根据有关法律法规，要炼在此郑重请您注意，要炼作为信息存储空间及网络交易平台的提供者，任何经由本网站以上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料（以下简称“内容”），无论系公开还是私下传送，均由内容提供者承担责任。要炼无法控制经由本网站传送之内容，因此不保证内容的正确性、完整性或品质。在任何情况下，要炼均不为任何内容负责，包含但不限于任何内容之任何错误或遗漏，以及经由本网站服务以张贴、发送电子邮件或其它方式传送任何内容而衍生之任何损失或损害。但要炼有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本网站服务的全部或部分，保存有关记录，并向有关机关报告。"
      )
    ]),
    _c("h4", [_vm._v("七、违约责任")]),
    _c("p", [
      _vm._v(
        "7.1 针对您违反本协议或其他服务条款的行为，本公司有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除内容或跟帖、短期禁止发布内容或评论、限制帐号部分或者全部功能直至永久关闭帐号等措施，对于因此而造成您无法正常使用帐号及相关服务、无法正常获取您帐号内资产或其他权益等后果，公司不承担任何责任。本公司有权公告处理结果，且有权根据实际情况决定是否恢复使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7.2 因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致本公司及其关联公司、控制公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿本公司及其关联公司、控制公司因此遭受的全部损失。"
      )
    ]),
    _c("p", [
      _vm._v(
        "7.3 本公司尊重并保护用户及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用”要炼 ”软件及相关服务时上传的文字、图片、视频、音频等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，本公司有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行处理并承担全部可能由此引起的法律责任；如因您的侵权行为导致本公司遭受损失的（包括经济、商誉等损失），您还应足额赔偿本公司遭受的全部损失。"
      )
    ]),
    _c("h4", [_vm._v("八、未成年人使用条款")]),
    _c("p", [
      _vm._v(
        "8.1 若您是未满18周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用“要炼”软件及相关服务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8.2 要炼重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并应当在取得监护人的同意以及在监护人指导下正确使用“要炼”软件及相关服务。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8.3 未成年人用户及其监护人理解并确认，如您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而可能导致的全部法律责任。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8.4 未成年人用户特别提示未成年人使用“要炼”软件及相关服务应在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，影响日常的学习生活，养成良好上网习惯。未成年人用户及监护人在使用“要炼”软件及相关服务使应必须遵守《全国青少年网络文明公约》： 要善于网上学习,不浏览不良信息。 要诚实友好交流,不侮辱欺诈他人。 要增强自护意识,不随意约会网友。 要维护网络安全,不破坏网络秩序。 要有益身心健康,不沉溺虚拟时空。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8.5 为更好地保护未成年人隐私权益，要炼特别提醒您慎重发布包含未成年人素材的内容，一经发布，即视为您已获得权利人同意在“要炼”软件及相关服务展示未成年人的肖像、声音等信息，且允许要炼依据本协议使用、处理该等与未成年人相关的内容。如权利人通知要炼，您发布的内容侵犯未成年人权利、出于保护权利人及未成年人权利的考虑，要炼有权对您发布的内容进行处理并通知您。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8.6 对于未成年人的隐私保护，要炼将严格按照平台公布的《要炼用户信息隐私协议》中阐述的方式、方法执行。"
      )
    ]),
    _c("p", [_vm._v("8.7 监护人特别提示")]),
    _c("p", [
      _vm._v(
        "8.7.1 法定监护人应指导子女上网应该注意的安全问题，防患于未然。如您的被监护人使用“要炼”软件及相关服务的，您作为监护人应指导并监督被监护人的注册和使用行为，如您的被监护人申请注册要炼帐号，要炼将有权认为其已取得您的同意。"
      )
    ]),
    _c("p", [
      _vm._v(
        "8.7.2 您的被监护人在使用“要炼”软件及相关服务时可能使用充值、要炼会员卡服务、商品/服务购买等功能。要炼不鼓励未成年人使用“要炼”软件及相关服务进行任何消费行为。您作为监护人，请保管好您的支付设备、支付账户及支付密码等，以避免被监护人在未取得您同意的情况下通过您的要炼帐号使用充值、要炼会员卡服务、商品/服务购买等功能。"
      )
    ]),
    _c("h4", [_vm._v("九、协议修改")]),
    _c("p", [
      _vm._v(
        "9.1 要炼有权根据法律法规政策、国家有权机构或公司经营要求修改本协议的有关条款，要炼将会通过适当方式在网站上予以公示。"
      )
    ]),
    _c("p", [
      _vm._v(
        "9.2 若您不同意要炼对本协议相关条款所做的修改，您有权停止使用”要炼 ”软件及相关服务。如果您继续使用”要炼 ”软件及相关服务，则视为您接受要炼对本协议相关条款所做的修改。"
      )
    ]),
    _c("h4", [_vm._v("十、其他")]),
    _c("p", [
      _vm._v(
        "10.1 本协议的订立、生效、履行、解释、修订、终止及纠纷解决，适用中华人民共和国法律（仅为本协议之目的，不包括香港特别行政区、澳门特别行政区和台湾地区）。"
      )
    ]),
    _c("p", [
      _vm._v(
        "10.2 本协议的签订地为中华人民共和国深圳市龙岗区。若您与公司发生任何争议，双方应尽量友好协商解决，如协商不成，您同意应将争议提交至要炼所在地有管辖权的人民法院提起诉讼。"
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "2.4 您应保证注册管理帐号时填写的身份信息的真实性并使用真实、准确、合法、有效的相关身份证明材料及必要信息 "
      ),
      _c("strong", [
        _vm._v(
          "（包括您的姓名及电子邮件地址、联系电话、联系地址等；企业用户需进行企业号认证，认证过程中需提交营业执照、商标使用权等资质）"
        )
      ]),
      _vm._v(
        "。依照国家相关法律法规的规定，为使用“要炼”软件及相关服务的部分功能，您需要按照相关法律规定完成实名认证/企业认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不准确、不真实、不规范或公司有理由怀疑为错误、不实、不合法的资料，则公司有权拒绝为您提供相关功能，您可能无法使用“要炼”软件及相关服务或在使用过程中部分功能受到限制。"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }