import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: ['orderDetail'],
  data: function data() {
    return {
      tableData: []
    };
  },
  methods: {
    itemSellAttributesFn: function itemSellAttributesFn(row) {
      var itemSellAttributes = row.itemSellAttributes;
      var s = itemSellAttributes.map(function (v) {
        return " ".concat(v.itemValue);
      });
      return s.join('/');
    }
  }
};