var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-body" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "h_left" }, [_vm._v("企业账号注册")]),
        _c("div", { staticClass: "h_right" }, [
          _vm._v("已有账号？请"),
          _c(
            "span",
            {
              staticClass: "loginTxt",
              on: {
                click: function($event) {
                  return _vm.$router.replace("login")
                }
              }
            },
            [_vm._v("点此登录")]
          )
        ])
      ]),
      _c("div", { staticClass: "page-content" }, [
        _c("div", { staticClass: "page-content-title" }, [
          _vm._v("企业账号注册")
        ]),
        _c(
          "div",
          { staticClass: "formBox" },
          [
            _c(
              "el-form",
              {
                ref: "loginForm",
                staticClass: "d2-pl",
                staticStyle: { width: "400px" },
                attrs: {
                  "label-position": "left",
                  "hide-required-asterisk": "",
                  rules: _vm.rules,
                  model: _vm.formLogin,
                  "label-width": _vm.labelWidth
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "accountPhone", label: "手机号码：" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "请输入手机号码",
                        maxlength: "11"
                      },
                      model: {
                        value: _vm.formLogin.accountPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.formLogin, "accountPhone", $$v)
                        },
                        expression: "formLogin.accountPhone"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-code",
                    attrs: { prop: "verifyCode", label: "验证码：" }
                  },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { type: "text", placeholder: "请输入验证码" },
                        model: {
                          value: _vm.formLogin.verifyCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formLogin, "verifyCode", $$v)
                          },
                          expression: "formLogin.verifyCode"
                        }
                      },
                      [
                        _c(
                          "template",
                          { slot: "append" },
                          [
                            _c("el-button", { on: { click: _vm.setTimeFn } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.timeInterval
                                    ? _vm.time + "s"
                                    : "获取验证码"
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "accountName", label: "用户名：" } },
                  [
                    _c("el-input", {
                      attrs: { type: "text", placeholder: "请输入用户名" },
                      model: {
                        value: _vm.formLogin.accountName,
                        callback: function($$v) {
                          _vm.$set(_vm.formLogin, "accountName", $$v)
                        },
                        expression: "formLogin.accountName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "accountPassword", label: "设置密码：" } },
                  [
                    _c("el-input", {
                      attrs: { type: "password", placeholder: "请输入密码" },
                      model: {
                        value: _vm.formLogin.accountPassword,
                        callback: function($$v) {
                          _vm.$set(_vm.formLogin, "accountPassword", $$v)
                        },
                        expression: "formLogin.accountPassword"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { padding: "20px 0 20px 85px" },
                    attrs: { flex: "main:left cross:center" }
                  },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.checked,
                          callback: function($$v) {
                            _vm.checked = $$v
                          },
                          expression: "checked"
                        }
                      },
                      [
                        _c("span", [_vm._v("我已阅读并同意")]),
                        _c(
                          "span",
                          {
                            staticStyle: { color: "#02a7f0" },
                            on: {
                              click: function($event) {
                                _vm.dialogFormVisible = true
                              }
                            }
                          },
                          [_vm._v("《要炼企业用户注册协议》")]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "d2-mt d2-mb button-login",
                    attrs: { type: "primary" },
                    on: { click: _vm.submit }
                  },
                  [_vm._v(" 注册账号并开店 ")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: { width: "60%", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              _vm.dialogFormVisible = false
            }
          }
        },
        [_c("regAgreement")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }