import { render, staticRenderFns } from "./addSendShop.page.vue?vue&type=template&id=8615de44&scoped=true&"
import script from "./addSendShop.page.vue?vue&type=script&lang=js&"
export * from "./addSendShop.page.vue?vue&type=script&lang=js&"
import style0 from "./addSendShop.page.vue?vue&type=style&index=0&id=8615de44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8615de44",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\mzkj\\要炼\\yl-pc-business\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8615de44')) {
      api.createRecord('8615de44', component.options)
    } else {
      api.reload('8615de44', component.options)
    }
    module.hot.accept("./addSendShop.page.vue?vue&type=template&id=8615de44&scoped=true&", function () {
      api.rerender('8615de44', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/distribution/sendShop/addSendShop.page.vue"
export default component.exports