//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import orderUsp from './comp/orderUsp.vue';
import orderTaber from './comp/orderTaber.vue';
export default {
  meta: {
    title: '换新发货详情',
    auth: true,
    authorityValue: "store-exchange-goods"
  },
  name: 'sendNewDetail',
  components: {
    orderUsp: orderUsp,
    orderTaber: orderTaber
  },
  data: function data() {
    return {
      tableData: []
    };
  },
  methods: {
    handleSearch: function handleSearch(item) {
      console.log(item);
    },
    handleNav: function handleNav(NAME) {
      this.$router.push({
        name: NAME
      });
    },
    confirm: function confirm() {}
  }
};