var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { attrs: { betterScroll: "" } },
    [
      _c("d2-page-head", { attrs: { slot: "header" }, slot: "header" }),
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("span", [_vm._v("取消自动审核：")]),
          _c("el-switch", {
            attrs: {
              value: _vm.switchFalse,
              "active-value": true,
              "inactive-value": false
            },
            on: { change: _vm.setAutoAudit }
          }),
          _c("span", { staticClass: "d2-ml" }, [
            _vm._v(
              "开启开关后，如果订单状态为“未出库”，系统将对此客户申请取消的此条订单自动审核通过，并触发退款。"
            )
          ]),
          _c("el-alert", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              closable: false,
              title:
                "超时系统自动审核条件：1.未出库超1天不处理（自然日）；2.已出库超1天不处理（自然日）",
              type: "warning"
            }
          })
        ],
        1
      ),
      _c(
        "base-form",
        {
          attrs: { form: _vm.form, initForm: _vm.initForm },
          on: {
            "update:form": function($event) {
              _vm.form = $event
            },
            search: _vm.handleSearch
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单号" },
                model: {
                  value: _vm.form.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "申请日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.applyTimeRange.start,
                  max: _vm.form.applyTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.applyTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户账号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入客户账号" },
                model: {
                  value: _vm.form.userNo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userNo", $$v)
                  },
                  expression: "form.userNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核日期" } },
            [
              _c("range-date", {
                attrs: {
                  min: _vm.form.auditTimeRange.start,
                  max: _vm.form.auditTimeRange.end
                },
                on: {
                  "update:min": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "start", $event)
                  },
                  "update:max": function($event) {
                    return _vm.$set(_vm.form.auditTimeRange, "end", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入客户姓名" },
                model: {
                  value: _vm.form.consumerName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "consumerName", $$v)
                  },
                  expression: "form.consumerName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _vm._l(_vm.tab, function(l, i) {
            return [
              _c("el-tab-pane", {
                key: i,
                attrs: {
                  label: "" + l.label + (l.limt ? "(" + l.limt + ")" : ""),
                  name: l.type
                }
              })
            ]
          })
        ],
        2
      ),
      _c(
        "base-table",
        {
          ref: "baseTable",
          staticStyle: { "margin-top": "20px" },
          attrs: { request: _vm.request }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "refundNo", label: "退款申请单号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "客户姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [_vm._v(_vm._s(row.nickname))]
                        ),
                        _c(
                          "el-link",
                          { attrs: { type: "primary", underline: false } },
                          [
                            _c("i", {
                              staticClass: "el-icon-chat-dot-round",
                              on: {
                                click: function($event) {
                                  return _vm.isImFormIdSet(row.userId)
                                }
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "userNo", label: "客户账号", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "applyTime", label: "申请时间", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "refundAmount", label: "退款金额", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "refundStatus", label: "退款状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("ftValue2label")(
                            row.refundStatus,
                            _vm.dict.cancelStatus
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderId",
              label: "订单编号",
              align: "center",
              width: "250"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.handleGoRoute(row.orderId)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.orderId))]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.copyText(row.orderId)
                              }
                            }
                          },
                          [_vm._v("复制")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "actualDeliveryStatus",
              label: "出库信息",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.delivered ? "出库" : "未出库"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goRoute("cancelOrderDetail", {
                              refundId: row.refundId
                            })
                          }
                        }
                      },
                      [_vm._v("服务单详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }